const INITIAL_OFFICE_DETAIL = {
    isLoaded: false,
    data: null
}

export const warehouseOfficeDetail = (state = INITIAL_OFFICE_DETAIL, action) => {
    switch(action.type) {
        case "FETCH_WAREHOUSE_OFFICE_DETAIL_RESET":
            return INITIAL_OFFICE_DETAIL;
        case "FETCH_WAREHOUSE_OFFICE_DETAIL_SUCCESS":
            return {...state, isLoaded: true, data: action.payload}
        case "FETCH_WAREHOUSE_OFFICE_DETAIL_FAILED":
            return {...state, isLoaded: true, data: action.payload}
        default:
            return state;
    }
}