import React from 'react';
import NumberFormat from 'react-number-format';

class DetailPembayaran extends React.Component {
    handleKonfirmasiPesananButtonClick = () => {
        this.props.handleKonfirmasiPesananButtonClick();
    }
    doRenderBayarDengan() {
        var {metode_pembayaran, zenny_terpakai, total_harga_barang_setelah_diskon, total_biaya_kirim, promo_biaya_kirim} = this.props;
        var total_final = total_harga_barang_setelah_diskon + total_biaya_kirim - promo_biaya_kirim;
        var bayar_dengan_tunai = 0;

        if (metode_pembayaran === "zenny") {
            bayar_dengan_tunai = total_final - zenny_terpakai;
        }
        else {
            bayar_dengan_tunai = total_final;
        }

        var renderedBayarDengan;
        if (metode_pembayaran === "zenny") {
            renderedBayarDengan = (
                <div>
                    <div className="ui grid">
                        <div className="row">
                            
                            <div className="ten wide column">
                                <img src="/assets/drawables/ic_zenny_icon.png" style={{width: "48px", height: "auto"}}></img>
                                
                            </div>
                            <div className="right aligned six wide column">
                                <NumberFormat value={zenny_terpakai} displayType={'text'} thousandSeparator={'.'} 
                                decimalSeparator={','} prefix={'Rp'} renderText={ value => <div className="">{value}</div> } />
                            </div>
                        </div>
                        <div className="row">
                            <div className="left aligned eight wide column">
                                <div>Bayar Tunai</div>
                                <NumberFormat value={bayar_dengan_tunai} displayType={'text'} thousandSeparator={'.'}
                                decimalSeparator={','} prefix={'Rp'}
                                renderText={value => <div className="">{value}</div>} />
                            </div>
                            <div className="right aligned eight wide column">
                                <button onClick={this.handleKonfirmasiPesananButtonClick} className="ui green button" style={{width: '100%'}} >Pesan</button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            renderedBayarDengan = (
                <div>
                    <div className="ui stretched grid">
                        <div className="row">
                            <div className="left aligned eight wide column">
                                <div>Bayar Tunai</div>
                                <NumberFormat value={bayar_dengan_tunai} displayType={'text'} thousandSeparator={'.'}
                                decimalSeparator={','} prefix={'Rp'}
                                renderText={value => <div className="">{value}</div>} />
                            </div>
                            <div className="right aligned eight wide column">
                                <button onClick={this.handleKonfirmasiPesananButtonClick} className="ui green button" style={{width: '100%'}} >Pesan</button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return renderedBayarDengan;
    }
    doRenderDetailPembayaran() {
        var {total_harga_barang_setelah_diskon, total_biaya_kirim, promo_biaya_kirim, metode_pembayaran, zenny_terpakai} = this.props;
        if (total_harga_barang_setelah_diskon != 0) {
            var total_final = total_harga_barang_setelah_diskon + total_biaya_kirim - promo_biaya_kirim;
            var biaya_kirim_final = 0, renderedBiayaKirim;
            if (promo_biaya_kirim > 0) {
                biaya_kirim_final = total_biaya_kirim - promo_biaya_kirim;
                renderedBiayaKirim = (
                    <div className="row">
                        <div className="left aligned ten wide column">
                            Biaya Kirim
                        </div>
                        <div className="right aligned six wide column">
                            <NumberFormat value={total_biaya_kirim} displayType={'text'} thousandSeparator={'.'}
                            decimalSeparator={','} prefix={'Rp'} renderText={value => <div><strike>{value}</strike></div>} />
                            <NumberFormat value={biaya_kirim_final} displayType={'text'} thousandSeparator={'.'}
                            decimalSeparator={','} prefix={'Rp'} renderText={value => <div>{value}</div>} />
                        </div>
                    </div>
                );
            }
            else {
                biaya_kirim_final = total_biaya_kirim;
                renderedBiayaKirim = (
                    <div className="row">
                        <div className="left aligned ten wide column">
                            Biaya Kirim
                        </div>
                        <div className="right aligned six wide column">
                            <NumberFormat value={biaya_kirim_final} displayType={'text'} thousandSeparator={'.'}
                            decimalSeparator={','} prefix={'Rp'} renderText={value => <div>{value}</div>} />
                        </div>
                    </div>
                )
            }

            return (
                <div className="detail-pembayaran">
                    <h4 className="heading">Detail Pembayaran</h4>
                    <div className="ui grid">
                        <div className="row">
                            <div className="left aligned ten wide column">
                                Subtotal
                            </div>
                            <div className="right aligned six wide column">
                                <NumberFormat value={total_harga_barang_setelah_diskon} displayType={'text'} thousandSeparator={'.'}
                                decimalSeparator={','} prefix={'Rp'} renderText={value => <div>{value}</div>} />
                            </div>
                        </div>
                        {renderedBiayaKirim}
                    </div>
                    <div className="ui grid">
                        <div className="row">
                            <div className="left aligned ten wide column">
                                Total
                            </div>
                            <div className="right aligned six wide column">
                                <NumberFormat value={total_final} displayType={'text'} thousandSeparator={'.'}
                                decimalSeparator={','} prefix={'Rp'} renderText={value => <div>{value}</div>} />
                            </div>
                        </div>
                    </div>
                    {this.doRenderBayarDengan()}
                </div>
            )
        }
        else return <div></div>
    }
    render() {
        return this.doRenderDetailPembayaran()
    }
}
export default DetailPembayaran;