import mapis from "../../api/mapis";
import Cookies from "universal-cookie";

export const fetchInsertTransaksiBaru = (transaksi) => async dispatch => {
    dispatch({
        type: "FETCH_INSERT_TRANSAKSI_RESET"
    });
    var url = `/mart/transaksi/insert`;
    const cookies = new Cookies();
    if (cookies.get("state")) {
        const response = await mapis.post(url, transaksi, {
            headers: {
                token: cookies.get("state")
            }
        });
    
        if (response.data.status === "success") {
            dispatch({
                type: "FETCH_INSERT_TRANSAKSI_SUCCESS",
                payload: response.data.total_harga_barang
            })
        }
        else {
            dispatch({
                type: "FETCH_INSERT_TRANSAKSI_FAILED"
            })
        }
    }
    else {
        dispatch({
            type: 'FETCH_SESSION_INVALID_TOKEN'
        })
    }
}