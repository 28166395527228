import React from 'react';
import {Helmet} from 'react-helmet';
import {connect} from 'react-redux';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { fetchUserRegister, fetchUserValidation } from '../actions/account';

import RegularBar from '../universal/RegularBar';
import '../css/Account/account.css'

const PNF = PhoneNumberFormat;
const phoneUtil = PhoneNumberUtil.getInstance();

class Register extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            nama: "",
            password: "",
            ponsel: "",
            kode_negara: "+62",
            isEmailValid: false,
            isPonselValid: false,
            errorEmailNotValid: "",
            errorPonselNotValid: "",
            tryToRegister: false
        }
    }
    validatePhoneNumberToServer = (ponselObject) => setTimeout(async () => {
        //console.log("Fetch validation...");
        await this.props.fetchUserValidation("telepon", ponselObject);
        if (this.props.accountValidation.isValidated === true && this.props.accountValidation.toValidate === "telepon") {
            if (this.props.accountValidation.result === true) {
                 this.setState({
                     ponsel: ponselObject.nomor_ponsel,
                     isPonselValid: false,
                     errorPonselNotValid: "Nomor ponsel sudah terpakai."
                 })
            }
            else {
                 this.setState({
                    ponsel: ponselObject.nomor_ponsel,
                     isPonselValid: true,
                     errorPonselNotValid: ""
                 })
            }
        }
        //console.log("Fetch DONE")
    }, 500) ;
    validateEmailToServer = (email) => setTimeout(async () => {
        //console.log("Fetch validation...");
        await this.props.fetchUserValidation("email", email);
        console.log("Sudah panggil server")
        if (this.props.accountValidation.isValidated === true && this.props.accountValidation.toValidate === "email") {
            if (this.props.accountValidation.result === true) {
                this.setState({
                    isEmailValid: false,
                    errorEmailNotValid: "Alamat email sudah terpakai"
                })
            }
            else {
                this.setState({
                    isEmailValid: true,
                    errorEmailNotValid: ""
                })
            }
        }
        //console.log("Fetch DONE")
    }, 500) ;

    doValidateEmail(email) {
        var regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        return regex.test(email.toLowerCase());
    }
    doValidatePhoneNumber(number) {
        try {
            const phoneNumber = phoneUtil.parseAndKeepRawInput(number, 'ID');
            if (phoneUtil.isValidNumberForRegion(phoneNumber, 'ID')) {
                //console.log("NUMBER", phoneNumber.getCountryCode() + " " + phoneNumber.getNationalNumber());
                return {
                    status: 1,
                    kode_negara: phoneNumber.getCountryCode().toString(),
                    nomor_ponsel: phoneNumber.getNationalNumber().toString()
                }
            }
            else {
                return {
                    status: 0
                }
            }
        }
        catch {
            return {
                status: -1
            }
        }
    }
    doRenderHeader() {
        return (
            <Helmet>
                <title>Daftar Akun - Henata</title>
            </Helmet>
        );
    }
    onNamaInputChange = (event) => {
        this.setState({
            nama: event.target.value.toString()
        })
    }
    onPasswordInputChange = (event) => {
        this.setState({
            password: event.target.value.toString().trim()
        })
    }
    onEmailInputChange = async (event) => {
        if (event.target.value !== "") {
           // await this.props.fetchUserValidation("email", event.target.value);
           if (this.doValidateEmail(event.target.value)) {
               this.setState({
                   isEmailValid: false,
                   errorEmailNotValid: "",
                   email: event.target.value
               })

               clearTimeout(this.timeoutId);
               var emailObject = {
                   email: event.target.value
               }
               this.timeoutId = this.validateEmailToServer(emailObject);
               
           }
           else {
               this.setState({
                   email: event.target.value,
                   isEmailValid: false,
                   errorEmailNotValid: "Alamat email tidak valid"
               })
           }
        }
        else {
            this.setState({
                email: event.target.value,
                isEmailValid: false,
                errorEmailNotValid: ""
            });
        }
    }
    onPonselInputChange = async (event) => {
        if (event.target.value !== "") {
            var ponselObject = this.doValidatePhoneNumber(event.target.value);
            if (ponselObject.status === 1) {
                this.setState({
                    isPonselValid: false,
                    errorPonselNotValid: "",
                    ponsel: event.target.value
                })
                clearTimeout(this.timeoutId);
                this.timeoutId = this.validatePhoneNumberToServer(ponselObject);
            }
            else {
                this.setState({
                    isPonselValid: false,
                    ponsel: event.target.value,
                    errorPonselNotValid: "Nomor ponsel tidak valid."
                })
            }
        }
        else {
            this.setState({
                isPonselValid: false,
                ponsel: event.target.value,
                errorPonselNotValid: ""
            })
        }
    }
    onFormSubmit = async (event) => {
        event.preventDefault();
        if (this.state.email !== "" && this.state.nama !== "" && this.state.password !== "" && this.state.ponsel !== "") {
            if (this.state.isEmailValid == true && this.state.isPonselValid == true) {
                if (window.confirm("Apakah data yang dimasukkan sudah sesuai?")) {
                    var registerObject = {
                        nama: this.state.nama.trim(),
                        email: this.state.email,
                        kode_negara: this.state.kode_negara,
                        telepon: this.state.ponsel,
                        password: this.state.password.trim()
                    }
                    await this.props.fetchUserRegister(registerObject);
        
                    window.alert("Akun berhasil terdaftar");
                }
            }
            else {
                window.alert("Periksa kembali data yang dimasukkan.")
            }
            
        }
        else {
            window.alert("Isian data masih belum lengkap.")
        }
    }
    doRenderPonselWarning() {
        if (this.state.ponsel !== "" && this.state.isPonselValid == false) {
            return <div className="ponsel_warning">{this.state.errorPonselNotValid}</div>
        }
        else {
            if (this.state.ponsel !== "") return <div className="ponsel_valid">Nomor ponsel dapat digunakan.</div>
            else return <div></div>
        }
    }
    doRenderEmailWarning() {
        if (this.state.email !== "" && this.state.isEmailValid == false) {
            return <div className="email_warning">{this.state.errorEmailNotValid}</div>
        }
        else {
            if (this.state.email !== "")return <div className="email_valid">Alamat email dapat digunakan.</div>
            else return <div></div>
        }
    }
    doRenderRegisterForm() {
        return (
            <div>
                <form className="ui form" onSubmit={this.onFormSubmit}>
                    <div className="field">
                        <label>Nama Lengkap</label>
                        <input type="text" placeholder="Masukkan nama lengkap.." value={this.state.nama} onChange={this.onNamaInputChange} />
                    </div>
                    <div className="field">
                        <label>Alamat Email</label>
                        <input type="email" placeholder="Masukkan alamat email.." value={this.state.email} onChange={this.onEmailInputChange} />
                        {this.doRenderEmailWarning()}
                    </div>
                    <div className="field">
                        <label>Nomor Ponsel</label>
                        <div className="ui labeled input">
                            <div className="ui label" id="kode_negara">+62</div>
                            <input type="number" placeholder="Masukkan nomor ponsel.." value={this.state.ponsel} onChange={this.onPonselInputChange} />
                        </div>
                        {this.doRenderPonselWarning()}
                    </div>
                    <div className="field">
                        <label>Password</label>
                        <input type="password" placeholder="Masukkan password.." value={this.state.password} onChange={this.onPasswordInputChange} />
                    </div>
                    <button className="ui green button">Daftar</button>
                </form>
            </div>
        )
    }
    doRenderPage() {
        if (!window.localStorage.getItem("nama_user")) {
            return (
                <div className="ui main container">
                    <div className="ui grid">
                        <div className="eight wide column">

                        </div>
                        <div className="eight wide column">
                        {this.doRenderRegisterForm()}
                        </div>
                    </div>
                    
                </div>
            );
        }
    }
    componentDidMount() {
        if (window.localStorage.getItem("id_user")) {
            //kalau udah login, maka redirect ke home
            window.location.replace("/")
        }
    }
    render() {
        //console.log("Rendering now... State status: ", this.state)
        return (
            <div>
                <RegularBar />
                {this.doRenderPage()}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        accountRegister: state.accountRegister,
        accountValidation: state.accountValidation
    }
}
const connectRegister = connect(mapStateToProps, {fetchUserRegister, fetchUserValidation})(Register);
export { connectRegister }