import React from 'react';
import NumberFormat from 'react-number-format';
import images from '../../../api/images';
import '../../css/Troli/troli.css';
import history from '../../../history';

class Product extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            jumlahString: ""
        }
    }
    
    onProductItemClick = event => {
        event.preventDefault();
        if (this.props.barang.url_slug_return && this.props.barang.url_slug_return !== "") {
            let path = `/product/${this.props.barang.url_slug_return}`;
            history.push(path);
        }
        
    }
    onMinusButtonClick = event => {
        event.preventDefault();
        if (this.state.jumlahString !== "") {
            var jumlahBaru = parseInt(this.state.jumlahString) - 1;
            if (jumlahBaru > 0) {
                this.props.onChangeQuantity(this.props.barang.id_barang_return, jumlahBaru)
                this.setState({
                    jumlahString: jumlahBaru.toString()
                })
            }
            //mentok cuman bisa sampai qty 1, tidak boleh dibawah 1
        }
        else {
            this.setState({
                jumlahString: "0"
            })
        }
    }
    onPlusButtonClick = event => {
        event.preventDefault();
        if (this.state.jumlahString !== "") {
            var jumlahBaru = parseInt(this.state.jumlahString) + 1;
            if (jumlahBaru > 0) {
                this.props.onChangeQuantity(this.props.barang.id_barang_return, jumlahBaru)
                this.setState({
                    jumlahString: jumlahBaru.toString()
                })
            }
            //mentok cuman bisa sampai qty 1, tidak boleh dibawah 1
        }
        else {
            this.setState({
                jumlahString: "1"
            })
        }
    }
    onBlurInput = (event) => {
        event.preventDefault();

        if (event.target.value !== "") {
            var jumlah_saat_blur = parseInt(event.target.value);
            if (jumlah_saat_blur == 0) {
                var {barang} = this.props;
                this.props.onChangeQuantity(barang.id_barang_return, 1)
                this.setState({
                    jumlahString: "1"
                })
            }
        }
        else {
            var {barang} = this.props;
            this.props.onChangeQuantity(barang.id_barang_return, 1)
            this.setState({
                jumlahString: "1"
            })
        }
    }
    onJumlahInputChange = (event) => {
        event.preventDefault();
        if (event.target.value !== "") {
            var jumlah_baru = parseInt(event.target.value);
            if (!Number.isNaN(jumlah_baru)) {
                if (jumlah_baru > 0) {
                    var {barang} = this.props;
                    this.props.onChangeQuantity(barang.id_barang_return, jumlah_baru)
                    this.setState({
                        jumlahString: jumlah_baru.toString()
                    })
                }
                else {
                    this.setState({
                        jumlahString: (0 - jumlah_baru).toString()
                    })
                }
            }
            else {
                this.setState({
                    jumlahString: ""
                })
            }
        }
        else {
            this.setState({
                jumlahString: ""
            })
        }
    }
    onDeleteProductButtonClick = () => {
        this.props.handleModalDeleteProductOpen(this.props.barang.id_barang_return)
    }

    
    componentDidMount() {
        var {pesanan} = this.props;
        if (pesanan) {
            this.setState({
                jumlahString: pesanan.jumlah_order.toString(),
            })
        }
        else {
            this.setState({
                jumlahString: "0"
            })
        }
    }
    componentWillUnmount() {
        if (this.state.jumlahString === "" || this.state.jumlahString === "0") {
            var {barang} = this.props;
            this.props.onChangeQuantity(barang.id_barang_return, 1)
        }
    }
    
    doRenderHarga() {
        var objectDiskon = this.props.diskon;
        var priceText = <div></div>;
        var diskonValue = 0, diskonPersen = 0, hargaSetelahDiskon = 0;

        if (objectDiskon.min_pembelian_return === -1 && objectDiskon.max_pembelian_return === -1) {
            diskonValue = Number(objectDiskon.diskon_value_return);
            diskonPersen = (Number(objectDiskon.diskon_persen_return) * Number(this.props.barang.harga_barang_return))/100;

            hargaSetelahDiskon = Number(this.props.barang.harga_barang_return) - diskonValue - diskonPersen;
            priceText = (
                <div>
                    <NumberFormat value={this.props.barang.harga_barang_return} displayType={'text'} thousandSeparator={'.'}
                    decimalSeparator={','} prefix={'Rp'}
                    renderText={value => <span className="product_p_dsc_harga_barang_normal_text"><strike>{value}</strike></span>} />
                    <NumberFormat value={hargaSetelahDiskon} displayType={'text'} thousandSeparator={'.'}
                    decimalSeparator={','} prefix={'Rp'}
                    renderText={value => <span className="product_p_dsc_harga_barang_after_diskon_text">{value}</span>} />
                </div>
            );
        }
        else if (objectDiskon.min_pembelian_return === -1 && objectDiskon.max_pembelian_return > 0) {
            diskonValue = Number(objectDiskon.diskon_value_return);
            diskonPersen = (Number(objectDiskon.diskon_persen_return) * Number(this.props.barang.harga_barang_return))/100;

            hargaSetelahDiskon = Number(this.props.barang.harga_barang_return) - diskonValue - diskonPersen;

            if (this.state.jumlahString !== "" && parseInt(this.state.jumlahString) <= objectDiskon.max_pembelian_return && parseInt(this.state.jumlahString) > 0) {
                priceText = (
                    <div>
                        <NumberFormat value={this.props.barang.harga_barang_return} displayType={'text'} thousandSeparator={'.'}
                        decimalSeparator={','} prefix={'Rp'}
                        renderText={value => <span className="product_p_dsc_harga_barang_normal_text"><strike>{value}</strike></span>} />
                        <NumberFormat value={hargaSetelahDiskon} displayType={'text'} thousandSeparator={'.'}
                        decimalSeparator={','} prefix={'Rp'}
                        renderText={value => <span className="product_p_dsc_harga_barang_after_diskon_text">{value}</span>} />
                    </div>
                )
            }
            else {
                priceText = (
                    <div>
                        <NumberFormat value={this.props.barang.harga_barang_return} displayType={'text'} thousandSeparator={'.'}
                        decimalSeparator={','} prefix={'Rp'}
                        renderText={value => <div className="product_p_harga_barang_text">{value}</div>}
                        />
                        <div className="product_p_diskon_condition_text">
                            Beli maks. {objectDiskon.max_pembelian_return} item,
                            <span style={{display: "inline-block"}}>
                                <NumberFormat value={hargaSetelahDiskon} displayType={'text'} thousandSeparator={'.'}
                                decimalSeparator={','} prefix={'Rp'}
                                renderText={value => <div>{value}</div>} />
                            </span>
                            / item.
                        </div>
                    </div>
                ); 
            }
        }
        else if (objectDiskon.min_pembelian_return > 0 && objectDiskon.max_pembelian_return === -1) {
            diskonValue = Number(objectDiskon.diskon_value_return);
            diskonPersen = (Number(objectDiskon.diskon_persen_return) * Number(this.props.barang.harga_barang_return))/100;

            hargaSetelahDiskon = Number(this.props.barang.harga_barang_return) - diskonValue - diskonPersen;
            
            if (objectDiskon.min_pembelian_return > 1) {
                if (this.state.jumlahString !== "" && parseInt(this.state.jumlahString) >= objectDiskon.min_pembelian_return) {
                    priceText = (
                        <div>
                            <NumberFormat value={this.props.barang.harga_barang_return} displayType={'text'} thousandSeparator={'.'}
                            decimalSeparator={','} prefix={'Rp'}
                            renderText={value => <span className="product_p_dsc_harga_barang_normal_text"><strike>{value}</strike></span>} />
                            <NumberFormat value={hargaSetelahDiskon} displayType={'text'} thousandSeparator={'.'}
                            decimalSeparator={','} prefix={'Rp'}
                            renderText={value => <span className="product_p_dsc_harga_barang_after_diskon_text">{value}</span>} />
                        </div>
                    )
                }
                else {
                    priceText = (
                        <div>
                            <NumberFormat value={this.props.barang.harga_barang_return} displayType={'text'} thousandSeparator={'.'}
                            decimalSeparator={','} prefix={'Rp'}
                            renderText={value => <div className="product_p_harga_barang_text">{value}</div>}
                            />
                            <div className="product_p_diskon_condition_text">
                                Beli min. {objectDiskon.min_pembelian_return} item,
                                <span style={{display: "inline-block"}}>
                                    <NumberFormat value={hargaSetelahDiskon} displayType={'text'} thousandSeparator={'.'}
                                    decimalSeparator={','} prefix={'Rp'}
                                    renderText={value => <div>{value}</div>} />
                                </span>
                                / item.
                            </div>
                        </div>
                    );
                }
            }
            else {
                priceText = (
                    <div>
                        <NumberFormat value={this.props.barang.harga_barang_return} displayType={'text'} thousandSeparator={'.'}
                        decimalSeparator={','} prefix={'Rp'}
                        renderText={value => <span className="product_p_dsc_harga_barang_normal_text"><strike>{value}</strike></span>} />
                        <NumberFormat value={hargaSetelahDiskon} displayType={'text'} thousandSeparator={'.'}
                        decimalSeparator={','} prefix={'Rp'}
                        renderText={value => <span className="product_p_dsc_harga_barang_after_diskon_text">{value}</span>} />
                    </div>
                );
            }
        }
        else if (objectDiskon.min_pembelian_return > 0 && objectDiskon.max_pembelian_return > 0) {
            diskonValue = Number(objectDiskon.diskon_value_return);
            diskonPersen = (Number(objectDiskon.diskon_persen_return) * Number(this.props.barang.harga_barang_return))/100;

            hargaSetelahDiskon = Number(this.props.barang.harga_barang_return) - diskonValue - diskonPersen;

            if (this.state.jumlahString !== "" && parseInt(this.state.jumlahString) >= objectDiskon.min_pembelian_return && parseInt(this.state.jumlahString) <= objectDiskon.max_pembelian_return) {
                priceText = (
                    <div>
                        <NumberFormat value={this.props.barang.harga_barang_return} displayType={'text'} thousandSeparator={'.'}
                        decimalSeparator={','} prefix={'Rp'}
                        renderText={value => <span className="product_p_dsc_harga_barang_normal_text"><strike>{value}</strike></span>} />
                        <NumberFormat value={hargaSetelahDiskon} displayType={'text'} thousandSeparator={'.'}
                        decimalSeparator={','} prefix={'Rp'}
                        renderText={value => <span className="product_p_dsc_harga_barang_after_diskon_text">{value}</span>} />
                    </div>
                )
            }
            else {
                priceText = (
                    <div>
                        <NumberFormat value={this.props.barang.harga_barang_return} displayType={'text'} thousandSeparator={'.'}
                        decimalSeparator={','} prefix={'Rp'}
                        renderText={value => <div className="product_p_harga_barang_text">{value}</div>}
                        />
                        <div className="product_p_diskon_condition_text">
                            Beli {objectDiskon.min_pembelian_return} - {objectDiskon.max_pembelian_return} item,
                            <span style={{display: "inline-block"}}>
                                <NumberFormat value={hargaSetelahDiskon} displayType={'text'} thousandSeparator={'.'}
                                decimalSeparator={','} prefix={'Rp'}
                                renderText={value => <div>{value}</div>} />
                            </span>
                            / item.
                        </div>
                    </div>
                );
            }
            

        }
        return priceText;
    }
    doRenderItem() {
        const {barang, diskon} = this.props;
        if (barang) {
            var hargaString;
            if (diskon) {
                hargaString = this.doRenderHarga();
            }
            else {
                hargaString = (
                    <div>
                        <NumberFormat value={barang.harga_barang_return} displayType={'text'} thousandSeparator={'.'}
                        decimalSeparator={','} prefix={'Rp'} renderText={ value => <div className="product_p_harga_barang_text">{value}</div> } />
                    </div>
                )
            }
            return (
                <div key={`troli_${barang.id_barang_return}`} className="item">
                    {/* <div className="card border shadow-item">
                        <div className="ui tiny image">
                            <img src={images(barang.main_img_url_return)}></img>
                        </div>
                    </div> */}
                    <div className="ui tiny image product_item" onClick={this.onProductItemClick}>
                        <div className="card border shadow-item">
                            <img src={images(barang.main_img_url_return)}></img>
                        </div>
                    </div>
                    <div className="middle aligned content">
                        <div className="ui grid">
                            <div className="ten wide column product_item" onClick={this.onProductItemClick}>
                                <div className="product_p_nama_barang_text">
                                    {barang.nama_barang_return}
                                </div>

                                {hargaString}
                            </div>
                            <div className="six wide column">
                                <div className="ui inline input">
                                    <div className="left-center" onClick={this.onMinusButtonClick}><img style={{width: "24px", height: "24px"}} src="/assets/drawables/minus.svg"></img></div>
                                    <input type="text" className="input-width" style={{textAlign: "center"}} onBlur={this.onBlurInput} value={this.state.jumlahString} onChange={this.onJumlahInputChange} ></input>
                                    <div className="right-center" onClick={this.onPlusButtonClick}><img style={{width: "24px", height: "24px"}} src="/assets/drawables/plus.svg"></img></div>
                                    <div className="right-center" onClick={this.onDeleteProductButtonClick}>
                                        <img src="/assets/drawables/ic_delete_button.svg" style={{width: '24px', height: '24px'}}></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
            
        }
    }
    render() {
        return this.doRenderItem()
    }
}

export default Product;