import React from 'react';
import NumberFormat from 'react-number-format';
import images from '../../../../api/images';

class Pesanan extends React.Component {
    constructor(props) {
        super(props);
    }

    doRenderDiskon() {
        var objectTroli = this.props.barang.troli;
        var objectDiskon = this.props.barang.diskon;

        var priceText = <div></div>;
        if (objectTroli && objectDiskon) {
            var diskonValue = 0, diskonPersen = 0, hargaSetelahDiskon = 0 //hargaSetelahDiskon khusus untuk suggestion
            var totalDiskonGabungan = 0, totalDiskonKaliQuantity = 0, quantityInt = parseInt(objectTroli.jumlah_order);
            var totalDiskonGabunganString, quantityString, totalDiskonKaliQuantityString;
            
            if (objectDiskon.min_pembelian_return === -1 && objectDiskon.max_pembelian_return === -1) {
                diskonValue = Number(objectDiskon.diskon_value_return);
                diskonPersen = (Number(objectDiskon.diskon_persen_return) * Number(this.props.barang.harga_barang_return))/100;
                
                totalDiskonGabungan = (diskonValue + diskonPersen);
                totalDiskonKaliQuantity = (diskonValue + diskonPersen) * quantityInt;
            }
            else if (objectDiskon.min_pembelian_return === -1 && objectDiskon.max_pembelian_return > 0) {
                diskonValue = Number(objectDiskon.diskon_value_return);
                diskonPersen = (Number(objectDiskon.diskon_persen_return) * Number(this.props.barang.harga_barang_return))/100;

                hargaSetelahDiskon = Number(this.props.barang.harga_barang_return) - diskonValue - diskonPersen;

                if (quantityInt <= objectDiskon.max_pembelian_return && quantityInt > 0) {
                    totalDiskonGabungan = (diskonValue + diskonPersen);
                    totalDiskonKaliQuantity = (diskonValue + diskonPersen) * quantityInt;
                }
            }
            else if (objectDiskon.min_pembelian_return > 0 && objectDiskon.max_pembelian_return === -1) {
                diskonValue = Number(objectDiskon.diskon_value_return);
                diskonPersen = (Number(objectDiskon.diskon_persen_return) * Number(this.props.barang.harga_barang_return))/100;

                hargaSetelahDiskon = Number(this.props.barang.harga_barang_return) - diskonValue - diskonPersen;
                
                if (objectDiskon.min_pembelian_return > 1) {
                    if ( quantityInt >= objectDiskon.min_pembelian_return) {
                        totalDiskonGabungan = (diskonValue + diskonPersen);
                        totalDiskonKaliQuantity = (diskonValue + diskonPersen) * quantityInt;
                    }
                }
                else {
                    totalDiskonGabungan = (diskonValue + diskonPersen);
                    totalDiskonKaliQuantity = (diskonValue + diskonPersen) * quantityInt;

                }
            }
            else if (objectDiskon.min_pembelian_return > 0 && objectDiskon.max_pembelian_return > 0) {
                diskonValue = Number(objectDiskon.diskon_value_return);
                diskonPersen = (Number(objectDiskon.diskon_persen_return) * Number(this.props.barang.harga_barang_return))/100;

                hargaSetelahDiskon = Number(this.props.barang.harga_barang_return) - diskonValue - diskonPersen;

                if (quantityInt >= objectDiskon.min_pembelian_return && quantityInt <= objectDiskon.max_pembelian_return) {
                    totalDiskonGabungan = (diskonValue + diskonPersen);
                    totalDiskonKaliQuantity = (diskonValue + diskonPersen) * quantityInt;
                }
            }
            return {
                totalDiskonGabungan: totalDiskonGabungan, 
                totalDiskonKaliQuantity: totalDiskonKaliQuantity
            }
        }
        else {
            return null;
        }
        //return priceText;
        
    }
    doRenderSubtotal() {
        const {barang} = this.props;
        if (barang && barang.troli) {
            var subtotalInt = parseInt(barang.harga_barang_return) * parseInt(barang.troli.jumlah_order);

            var objectDiskon = this.doRenderDiskon();
            
            var hargaString = (
                <div>
                    <NumberFormat value={barang.harga_barang_return} displayType={'text'} thousandSeparator={'.'}
                    decimalSeparator={','} prefix={'Rp'} renderText={ value => <div className="product_p_harga_barang_text">{value}</div> } />
                </div>
            );
            var quantityString = <NumberFormat value={Number(barang.troli.jumlah_order)} displayType={'text'} thousandSeparator={'.'}
            decimalSeparator={','} prefix={'x '} renderText={ value => <div>{value}</div>} />;
            
            var subtotalString = <NumberFormat value={subtotalInt} displayType={'text'} thousandSeparator={'.'} 
            decimalSeparator={','} prefix={'Rp'} renderText={ value => <div>{value}</div>} />

            var priceText = <div></div>;
            if (objectDiskon) {
                priceText = (
                    <div className="ui grid" >
                        <div className="six wide column">
                            <div>{hargaString}</div>
                            <div>
                                <NumberFormat value={objectDiskon.totalDiskonGabungan} displayType={'text'} thousandSeparator={'.'}
                                decimalSeparator={','} prefix={'- Rp'} renderText={value => <div>{value}</div>} />
                            </div>
                        </div>
                        <div className="four wide column"><div>{quantityString}</div><div>{quantityString}</div></div>
                        <div className="right aligned six wide column">
                            <div>{subtotalString}</div>
                            <div>
                            <NumberFormat value={objectDiskon.totalDiskonKaliQuantity} displayType={'text'} thousandSeparator={'.'}
                                decimalSeparator={','} prefix={'- Rp'} renderText={value => <div>{value}</div>} />
                            </div>
                        </div>
                    </div>
                )
            }
            else {
                priceText = (
                    <div className="ui grid" >
                        <div className="six wide column">
                            <div>
                            {hargaString}
                            </div>
                            
                        </div>
                        <div className="four wide column">{quantityString}</div>
                        <div className="right aligned six wide column">
                            {subtotalString}
                        </div>
                        
                    </div>
                )
            }
            return priceText;
        }
        else {
            return <div></div>
        }
    }
    doRenderItem() {
        const {barang} = this.props;
        if (barang && barang.troli) {
            return (
                <div key={`troli_${barang.id_barang_return}`} className="item">
                    <div className="ui tiny image product_item" onClick={this.onProductItemClick}>
                        <div className="card border shadow-item">
                            <img src={images(barang.main_img_url_return)}></img>
                        </div>
                    </div>
                    <div className="middle aligned content">
                        <div className="product_p_nama_barang_text">
                            {barang.nama_barang_return}
                        </div>
                        {this.doRenderSubtotal()}
                    </div>
                </div>
            )
            
        }
    }
    render() {
        return this.doRenderItem();
    }
}

export default Pesanan;