import React from 'react';
import { connect } from 'react-redux';
import { fetchProductInfo } from '../actions/product';
import { fetchDiskonByInventaris } from '../actions/diskon';
import NumberFormat from 'react-number-format';
// import DownloadApps from '../universal/DownloadApps';
import QuantityToTroli from '../page_components/ProductDetail/QuantityToTroli';
import MetodePemesanan from '../universal/MetodePemesanan';
//import SearchBar from '../universal/SearchBar';
import { connectSearchPanel as SearchBar} from '../universal/SearchBar';
import { Helmet } from 'react-helmet';
import { titleCase, MOBILE_SITE, DESKTOP_SITE, TWITTER_SITE } from '../actions/types';
import { Tab, Modal } from 'semantic-ui-react';

import images from '../../api/images';
import history from '../../history';
import '../css/product-detail.css'

const inlineStyle = {
    modal : {
        position: 'relative',
        height: 'auto',
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  };

class ProductDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_modal_masuk_troli_open: false
        }
    }
    //modal troli
    handleModalMasukTroliOpen = () => {
        this.setState({
            is_modal_masuk_troli_open: true
        })
    }
    handleModalMasukTroliClose = () => {
        this.setState({
            is_modal_masuk_troli_open: false
        })
    }
    onLihatTroliButtonClick = (event) => {
        event.preventDefault();
        let path = `/troli`;
        history.push(path);
    }
    //--
    showProductImage() {
        if (this.props.productInfo.isLoaded === true) {
            return (
                <img alt={this.props.productInfo.product.nama_barang_return} src={images(this.props.productInfo.product.main_img_url_return)} style={{
                    height: 'auto', width: '100%', marginLeft:'auto', marginRight: 'auto', display: 'block'
                    }} />
            );
        }
        else {
            return <div></div>
        }
    }
    isNotEmpty = (element) => {
        return element && element !== "" ? true: false;
    }
    generateProductTitle = (product) => {
        var satuanNettoMeasurement = "";
        if (product.nama_satuan_barang_return && product.nama_satuan_barang_return !== "") {
            if (this.isNotEmpty(product.netto_return) && this.isNotEmpty(product.kode_measurement_return)) {
                if (product.jumlah_pcs_return > 1) {
                    satuanNettoMeasurement = `${product.nama_satuan_barang_return} (${product.jumlah_pcs_return} x ${Number(product.netto_return)}${product.kode_measurement_return})`
                }
                else {
                    satuanNettoMeasurement = `${product.nama_satuan_barang_return}  (${Number(product.netto_return)}${product.kode_measurement_return})`
                }
            }
            else {
                satuanNettoMeasurement = product.nama_satuan_barang_return;
            }
        }
        if (this.isNotEmpty(satuanNettoMeasurement)) {
            return `Jual ${product.nama_barang_return} ${satuanNettoMeasurement} - Henata`
        }
        else {
            return `Jual ${product.nama_barang_return} - Henata`
        }
    }
    showProductProperty() {
        var productProperty;
        var {product} = this.props.productInfo;
        if (product.nama_satuan_barang_return && product.nama_satuan_barang_return !== "") {
            if (this.isNotEmpty(product.netto_return) && this.isNotEmpty(product.kode_measurement_return)) {
                if (product.jumlah_pcs_return > 1) {
                    productProperty = (
                        <h4 className="pd_property_barang_text">
                            {`${product.nama_satuan_barang_return} (${product.jumlah_pcs_return} x ${product.netto_return}${product.kode_measurement_return})`}
                        </h4>
                    )
                }
                else {
                    productProperty = (
                        <h4 className="pd_property_barang_text">
                            {`${product.nama_satuan_barang_return} (${product.netto_return}${product.kode_measurement_return})`}
                        </h4>
                    )
                }
            }
            else {
                productProperty = (
                    <h4 className="pd_property_barang_text">
                        {`${product.nama_satuan_barang_return}`}
                    </h4>
                )
            }
        }
        else {
            productProperty = <div></div>
        }
        return productProperty;
    }
    showProductPrice() {
        var priceText = <div></div>;
        if (this.props.diskonInfo.isDiskonExist === true) {
            var diskonValue = 0, diskonPersen = 0;
            var objectDiskonInfo = this.props.diskonInfo;
            var hargaSetelahDiskon = this.props.productInfo.product.harga_barang_return;

            if (objectDiskonInfo.diskon.min_pembelian_return === -1 && objectDiskonInfo.diskon.max_pembelian_return === -1) {
                diskonValue = Number(objectDiskonInfo.diskon.diskon_value_return);
                diskonPersen = (Number(objectDiskonInfo.diskon.diskon_persen_return) * Number(this.props.productInfo.product.harga_barang_return))/100;

                hargaSetelahDiskon = this.props.productInfo.product.harga_barang_return - diskonValue - diskonPersen;
                priceText = (
                    <div>
                        <NumberFormat value={this.props.productInfo.product.harga_barang_return} displayType={'text'} thousandSeparator={'.'}
                        decimalSeparator={','} prefix={'Rp'}
                        renderText={value => <div className="pd_dsc_harga_barang_normal_text"><strike>{value}</strike></div>} />
                        <NumberFormat value={hargaSetelahDiskon} displayType={'text'} thousandSeparator={'.'}
                        decimalSeparator={','} prefix={'Rp'}
                        renderText={value => <div className='pd_dsc_harga_barang_after_diskon_text'>{value}</div>} />
                    </div>
                );
            }
            else if (objectDiskonInfo.diskon.min_pembelian_return === -1 && objectDiskonInfo.diskon.max_pembelian_return > 0) {
                diskonValue = Number(objectDiskonInfo.diskon.diskon_value_return);
                diskonPersen = (Number(objectDiskonInfo.diskon.diskon_persen_return) * Number(this.props.productInfo.product.harga_barang_return))/100;

                hargaSetelahDiskon = this.props.productInfo.product.harga_barang_return - diskonValue - diskonPersen;
                priceText = (
                    <div>
                        <NumberFormat value={this.props.productInfo.product.harga_barang_return} displayType={'text'} thousandSeparator={'.'}
                        decimalSeparator={','} prefix={'Rp'}
                        renderText={value => <div className="pd_harga_barang_text">{value}</div>} />
                        <div className="pd_diskon_condition_text">
                            Pembelian maksimal {objectDiskonInfo.diskon.max_pembelian_return} item,
                            <span style={{display: "inline-block"}}>
                                <NumberFormat value={hargaSetelahDiskon} displayType={'text'} thousandSeparator={'.'}
                                decimalSeparator={','} prefix={'Rp'}
                                renderText={value => <div>{value}</div>} />
                            </span>
                            / item.
                        </div>
                    </div>
                );
            }
            else if (objectDiskonInfo.diskon.min_pembelian_return > 0 && objectDiskonInfo.diskon.max_pembelian_return === -1) {
                diskonValue = Number(objectDiskonInfo.diskon.diskon_value_return);
                diskonPersen = (Number(objectDiskonInfo.diskon.diskon_persen_return) * Number(this.props.productInfo.product.harga_barang_return))/100;

                hargaSetelahDiskon = this.props.productInfo.product.harga_barang_return - diskonValue - diskonPersen;
                priceText = (
                    <div>
                        <NumberFormat value={this.props.productInfo.product.harga_barang_return} displayType={'text'} thousandSeparator={'.'}
                        decimalSeparator={','} prefix={'Rp'}
                        renderText={value => <div className="pd_harga_barang_text">{value}</div>} />
                        <div className="pd_diskon_condition_text">
                            Pembelian minimal {objectDiskonInfo.diskon.min_pembelian_return} item,
                            <span style={{display: "inline-block"}}><NumberFormat value={hargaSetelahDiskon} displayType={'text'} thousandSeparator={'.'}
                            decimalSeparator={','} prefix={'Rp'}
                            renderText={value => <div>{value}</div>} /> </span>
                            / item.
                        </div>
                    </div>
                );
            }
            else if (objectDiskonInfo.diskon.min_pembelian_return > 0 && objectDiskonInfo.diskon.max_pembelian_return > 0) {
                diskonValue = Number(objectDiskonInfo.diskon.diskon_value_return);
                diskonPersen = (Number(objectDiskonInfo.diskon.diskon_persen_return) * Number(this.props.productInfo.product.harga_barang_return))/100;

                hargaSetelahDiskon = this.props.productInfo.product.harga_barang_return - diskonValue - diskonPersen;
                priceText = (
                    <div>
                        <NumberFormat value={this.props.productInfo.product.harga_barang_return} displayType={'text'} thousandSeparator={'.'}
                        decimalSeparator={','} prefix={'Rp'}
                        renderText={value => <div className="pd_harga_barang_text">{value}</div>} />
                        <div className="pd_diskon_condition_text">
                            Pembelian sejumlah {objectDiskonInfo.diskon.min_pembelian_return} s/d {objectDiskonInfo.diskon.max_pembelian_return} item,
                            <span style={{display: "inline-block"}}><NumberFormat value={hargaSetelahDiskon} displayType={'text'} thousandSeparator={'.'}
                            decimalSeparator={','} prefix={'Rp'}
                            renderText={value => <div>{value}</div>} /> </span>
                            / item.
                        </div>
                    </div>
                );
            }
        }
        else {
            priceText = (
                <div>
                    <NumberFormat value={this.props.productInfo.product.harga_barang_return} displayType={'text'} thousandSeparator={'.'}
                    decimalSeparator={','} prefix={'Rp'}
                    renderText={value => <div className="pd_harga_barang_text">{value}</div>} />
                    
                </div>
            );
        }

        return priceText;
    }
    showMasukTroliModal = () => {
        var {product} = this.props.productInfo;
        if (product) {
            var modalMessage = (
                <div>
                    <p>{`${product.nama_barang_return} telah dimasukkan ke troli belanja.`}</p>
                    <button className="ui green button" onClick={this.onLihatTroliButtonClick}>Lihat Troli Belanja</button>
                </div>
            )
            return (
                <Modal size="tiny"
                    style={inlineStyle.modal}
                    open={this.state.is_modal_masuk_troli_open}
                    onClose={this.handleModalMasukTroliClose} >
                    <Modal.Content >
                        {modalMessage}
                    </Modal.Content>
                </Modal>
            )
        }
        
    }
    showDeliveryArea() {
        return (
            <div className="delivery-area">
                <div className="ui warning message">Area pengiriman tersedia di Surabaya</div>
            </div>
        );
    }
    showProductInfoTabs() {
        var deskripsiProduk = "";
        if (this.props.productInfo.product.deskripsi_return !== "" && this.props.productInfo.product.deskripsi_return != null) {
            deskripsiProduk = this.props.productInfo.product.deskripsi_return;
        }
        else {
            deskripsiProduk = "Tidak ada deskripsi.";
        }
        const panes = [
            { 
                menuItem: "Deskripsi Produk", 
                render: ()=> <Tab.Pane><div className="message" dangerouslySetInnerHTML={{__html: deskripsiProduk }} ></div></Tab.Pane> 
            }
        ];
        return (
            <div>
                {/* <Tab menu={{ borderless: true, attached: false, tabular: false, secondary: true, pointing: true }} panes={panes} /> */}
                {/* <div className="ui top attached tabular menu">
                    <a className="item active" dataTab="first"><p className="title">Deskripsi Produk</p></a>
                    <a className="item" dataTab="second"><p className="title">Pengiriman</p></a>
                </div>
                <div className="ui bottom attached tab segment active" dataTab="first">
                    <div className="message" dangerouslySetInnerHTML={{__html: deskripsiProduk }} ></div>
                </div>
                <div className="ui bottom attached tab segment" dataTab="second">
                    <div className="message" >Hahaha</div>
                </div> */}
                <div>
                    <h2>Deskripsi Produk</h2>
                    <div className="message" dangerouslySetInnerHTML={{__html: deskripsiProduk }} ></div>
                </div>
            </div>
        );
    }
    renderProductInfo() {
        return (
            <div className="ui main container">
                <div className="ui grid">
                    <div className="five wide column">
                        <div className="card">
                            <div className="card-body" style={{width: '100%', margin: '0 auto'}}>
                                {this.showProductImage()}
                            </div>
                        </div>
                        
                    </div>
                    <div className="eleven wide column">
                        <h2 className="nama_barang_text">{this.props.productInfo.product.nama_barang_return}</h2>
                        <div>
                            {this.showProductProperty()}
                        </div>
                        <div>
                            {this.showProductPrice()}
                        </div>
                        <QuantityToTroli product={this.props.productInfo.product} handleModalMasukTroliOpen={this.handleModalMasukTroliOpen} />
                        <MetodePemesanan />
                        {/* {this.showDeliveryArea()}  */}
                    </div>
                </div>
                <div className="product-info-tab">
                    {this.showProductInfoTabs()}
                </div>
            </div>
        );
    }
    renderLoadingPage() {
        return (
            <div className="ui main container">
                <h1>Loading...</h1>
            </div>
        );
    }
    async loadFromApi() {
        //console.log(this.props.match)
        await this.props.fetchProductInfo(this.props.match.params.slug);
        //console.log(this.props.productInfo);
        if (this.props.productInfo.isLoaded === true) {
            this.props.fetchDiskonByInventaris(this.props.productInfo.product.id_barang_inventaris_return)
        }
    }
    componentDidMount() {
        window.scrollTo(0,0)
        //console.log(this.props.match)
        this.loadFromApi();
    }
    doRenderPage() {
        if (this.props.productInfo.isLoaded === true) {
            return this.renderProductInfo();
        }
        else {
            return this.renderLoadingPage();
        }
    }
    doRenderHeader() {
        if (this.props.productInfo.isLoaded === true) {
            //var titleStr = "Jual " + this.props.productInfo.product.nama_barang_return + " murah - Henata";
            var titleStr = this.generateProductTitle(this.props.productInfo.product);
            
            return (
                <Helmet>
                    <title>{titleStr}</title>
                </Helmet>
            );
        }
        else {
            return (
                <Helmet>
                    <title></title>
                </Helmet>
            )
        }
    }
    render() {
        //console.log(this.props.productInfo);
        return (
            <div>
                {this.doRenderHeader()};
                <SearchBar />
                <div>
                    {this.doRenderPage()}
                    {this.showMasukTroliModal()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return { productInfo: state.productInfo, diskonInfo: state.diskonByInventaris }
}

export default connect(mapStateToProps, { fetchProductInfo, fetchDiskonByInventaris })(ProductDetail);