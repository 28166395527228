import React from 'react';
import NumberFormat from 'react-number-format';
import history from '../../../history';

class RingkasanBelanja extends React.Component {
    onClickCheckoutButton = () => {
        let path = `/troli/checkout`;
        history.push(path);
    }
    doRenderRingkasanBelanja() {
        return (
            <div>
                <div className="ui grid">
                    <div className="left aligned eight wide column">
                        Subtotal
                    </div>
                    <div className="right aligned eight wide column">
                        <NumberFormat value={this.props.ringkasan.harga_subtotal} displayType={'text'} thousandSeparator={'.'}
                        decimalSeparator={','} prefix={'Rp'}
                        renderText={value => <div className="ringkasan_subtotal_text">{value}</div>} />
                    </div>
                    <div className="left aligned eight wide column">
                        Diskon
                    </div>
                    <div className="right aligned eight wide column">
                        <NumberFormat value={this.props.ringkasan.harga_diskon} displayType={'text'} thousandSeparator={'.'}
                        decimalSeparator={','} prefix={'Rp'}
                        renderText={value => <div className="ringkasan_diskon_text">{`- ${value}`}</div>} />
                    </div>
                </div>
                <div className="ringkasan_total_final_grid">
                    <div className="ui grid" >
                        <div className="left aligned eight wide column">
                            <div>Total</div>
                            <NumberFormat value={this.props.ringkasan.harga_total_final} displayType={'text'} thousandSeparator={'.'}
                            decimalSeparator={','} prefix={'Rp'}
                            renderText={value => <div className="ringkasan_total_final_text">{value}</div>} />
                        </div>
                        <div className="right aligned eight wide column">
                            <button className="ui red button" style={{width: '100%'}} onClick={this.onClickCheckoutButton}>Checkout</button>
                        </div>
                    </div>
                </div>
                
            </div>
        )
    }
    componentDidMount() {

    }
    render() {
        return (
            <div className="ui segment">
                {this.doRenderRingkasanBelanja()}
            </div>
        )
    }
}

export default RingkasanBelanja;