import React from 'react';
import {connect} from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import Cookies from 'universal-cookie';
import axios from 'axios';

import history from '../../../history'

class AccountMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.cookies = new Cookies();
    }
    doActionLogout = async () => {
        window.localStorage.clear();
        this.cookies.remove("state", { path: '/' });
        this.cookies.remove("user", { path: '/' });
        
        window.location.replace('/');
    }
    handleDropdownChange = (event, {value}) => {
        //console.log("Value", value);
        if (value === "troli") {
            let path = `/troli`;
            history.push(path);
        }
        else if (value === "logout") {
            this.doActionLogout();
        }
    }
    componentDidMount() {
        if (this.cookies.get("user")) {
            this.setState({
                "nama_user": this.cookies.get("user").nama_user_return
            })
        }
        
    }
    doRenderDropdown() {
        const dropdownOptions = [
            {
                key: "user",
                text: (
                    <span>
                        Masuk sebagai {this.state.nama_user}
                    </span>
                ),
                disabled: true
            },
            { key: "profile", text: "Profil", value:"profile" },
            { key: "troli", text: "Troli", value:"troli"},
            { key: "logout", text: "Keluar", value:"logout"}
        ];
        if (this.state.nama_user !== null) {

            return (
                <Dropdown
                item
                simple
                text={this.state.nama_user}
                options={dropdownOptions}
                onChange={this.handleDropdownChange}
                />
            );
        }
        else return <div></div>
    }
    render() {
        return (
            <div>
                {this.doRenderDropdown()}
            </div>
        );
    }
}

export default AccountMenu;