import React from 'react';
import { fetchKategoriFromUrlSlug, 
    fetchSubKategoriFromUrlSlug,
    resetKategoriAndSubKategoriUrlSlug,
    fetchKategoriAndSubKategoriUrlSlug, 
    fetchKategoriAndSubKategori } from '../actions/kategori';
import { fetchSearchProductByKategoriWithDiskon } from '../actions/search';
import { connect } from 'react-redux';
import { Menu, Breadcrumb } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import KategoriList from '../kategori/KategoriList';
import SearchList from '../search/SearchList';
import Pagination from '../universal/Pagination';
//import SearchBar from '../universal/SearchBar';
import { connectSearchPanel as SearchBar} from '../universal/SearchBar';
import history from '../../history';
import {MOBILE_SITE, DESKTOP_SITE, OG_DEFAULT_IMAGE, TWITTER_SITE} from '../actions/types';

import '../css/kategori.css'
import '../css/search.css'

class KategoriAll extends React.Component {
    componentDidMount() {
        window.scrollTo(0,0);
    }
    doRenderHeader() {
        var titleStr = "Jelajahi Semua Kategori - Henata";
        var descriptionStr = "Temukan produk kebutuhan yang anda cari dengan menjelajah semua kategori di henata. Belanja di henata, dapatkan harga murah, aman, terpercaya"
        return (
            <Helmet>
                <title>{titleStr}</title>
                <link rel="alternate" media="only screen and (max-width: 640px)" href={MOBILE_SITE + this.props.location.pathname} />
                <meta name="description" content={descriptionStr} />

                <meta property="og:title" content={titleStr} />
                <meta property="og:type" content="website" />
                <meta property="og:description" content={descriptionStr} />
                <meta property="og:image" content={OG_DEFAULT_IMAGE} />
                <meta property="og:url" content={DESKTOP_SITE + this.props.location.pathname} />

                <meta name="twitter:card" content="summary" />
                <meta name="twitter:site" content={TWITTER_SITE} />
            </Helmet>
        );
    }
    render() {
        return (
            <div>
                {this.doRenderHeader()}
                <SearchBar />
                <div className="ui main container">
                    <div>
                        <KategoriList />
                    </div>
                </div>
            </div>
            
        );
    }
}
class KategoriDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activePage: 1
        };

        this.unlisten = history.listen( async ({ pathname, state }) => {
            if (state) {
                if (state.kategori && state.sub_kategori) {
                    //console.log("Product kategori dan sub kategori")
                    await this.props.fetchKategoriAndSubKategoriUrlSlug(state.kategori, state.sub_kategori);
                    //console.log("Product panggil dari unlisten : kat dan subkat")
                    if (this.props.kategoriUrlSlug.url_slug === state.kategori && 
                        this.props.subkategoriUrlSlug.url_slug === state.sub_kategori) {
                            await this.loadProductFromPage(1, state.kategori, state.sub_kategori);
                        }
                    
                }
                else if (state.kategori) {
                    //console.log("Product kategori saja")
                    await this.props.fetchKategoriFromUrlSlug(state.kategori);
                    //console.log("Product panggil dari unlisten : kat saja")
                    if (this.props.kategoriUrlSlug.url_slug === state.kategori) {
                        await this.loadProductFromPage(1, state.kategori, null);
                    }
                }
            }
            else {
                var arrayPathname = pathname.split("/");
                var kategori = arrayPathname[2];
                var sub_kategori = arrayPathname[3];
                if (kategori && sub_kategori) {
                    await this.props.fetchKategoriAndSubKategoriUrlSlug(kategori, sub_kategori);
                    //console.log("Product panggil dari unlisten : kat dan subkat")
                    if (this.props.kategoriUrlSlug.url_slug === kategori && 
                        this.props.subkategoriUrlSlug.url_slug === sub_kategori) {
                            await this.loadProductFromPage(1, kategori, sub_kategori);
                        }
                }
                else if (kategori) {
                    await this.props.fetchKategoriFromUrlSlug(kategori);
                    //console.log("Product panggil dari unlisten : kat saja")
                    if (this.props.kategoriUrlSlug.url_slug === kategori) {
                        await this.loadProductFromPage(1, kategori, null);
                    }
                }
            }
        })
    }

    //click handler
    handleKategoriSubMenuClick = async (event, {subKatSlug, katSlug}) => {
        let path = `/kategori/${katSlug}/${subKatSlug}`;
        //this.loadFromApi();
        // await this.props.fetchKategoriAndSubKategoriUrlSlug(katSlug, subKatSlug);
        // await this.loadProductFromPage(1);
        //this.props.resetKategoriAndSubKategoriUrlSlug();
        history.push(path, {kategori: katSlug, sub_kategori: subKatSlug});
    }
    handleKategoriMenuClick = async (katSlug) => {
        let path = `/kategori/${katSlug}`;
        //this.loadFromApi();
        // await this.props.fetchKategoriFromUrlSlug(katSlug);
        // await this.loadProductFromPage(1);
        //this.props.resetKategoriAndSubKategoriUrlSlug();
        history.push(path, {kategori: katSlug});
    }

    async loadFromApi() {
        const { kat_url_slug, subkat_url_slug } = this.props.match.params;
        //console.log(this.props.match.params)

        if (kat_url_slug && subkat_url_slug) {
            await this.props.fetchKategoriAndSubKategoriUrlSlug(kat_url_slug, subkat_url_slug);
            //console.log("Product panggil dari loadFromApi : kat " + kat_url_slug + " dan subkat " + subkat_url_slug);
            if (this.props.kategoriUrlSlug.url_slug === kat_url_slug && this.props.subkategoriUrlSlug.url_slug === subkat_url_slug) {
                await this.loadProductFromPage(1, kat_url_slug, subkat_url_slug);
            }
        }
        else if (kat_url_slug) {
            await this.props.fetchKategoriFromUrlSlug(kat_url_slug);
            //console.log("Product panggil dari loadFromApi : kat : " + kat_url_slug)
            if (this.props.kategoriUrlSlug.url_slug === kat_url_slug) {
                await this.loadProductFromPage(1, kat_url_slug, null);
            }
        }
        await this.loadKategoriListFromApi();
    }
    async loadKategoriListFromApi() {
        const { listKategoriAll } = this.props;
        if (listKategoriAll.isLoaded === false) {
            await this.props.fetchKategoriAndSubKategori();
        }
    }
    async loadProductFromPage(page, kat_url_slug, subkat_url_slug) {
        const { kategoriUrlSlug, subkategoriUrlSlug } = this.props;
        if (kat_url_slug != null && subkat_url_slug != null) {
            if (kategoriUrlSlug.isLoaded === true && kategoriUrlSlug.isFound === true && 
                subkategoriUrlSlug.isLoaded === true && subkategoriUrlSlug.isFound === true) {
                //kalau kategori = found dan subkategori = found, maka call fetchSearchProduct
                //console.log("Product: dari kategoriUrlSlug : " + kategoriUrlSlug.data.id_return + " dan subkategoriUrlSlug : " + subkategoriUrlSlug.data.id_return)
                if (kategoriUrlSlug.url_slug === kat_url_slug && subkategoriUrlSlug.url_slug === subkat_url_slug) {
                    await this.props.fetchSearchProductByKategoriWithDiskon(
                        kategoriUrlSlug.data.id_return,
                        subkategoriUrlSlug.data.id_return,
                        page-1
                    );
                    //console.log("Product SUbkategori", this.props.resultKategori)
                }
            }
            else {
                //console.log("Kategori/subkategori Ngga ada")
                //kalau salah satu ada yg ga found, maka render div not found
            }
        }
        else if (kat_url_slug != null && subkat_url_slug == null) {
            //kalau cuman kategori aja, maka load aja dengan subkategori -1
            //console.log("Product dari kat saja", kat_url_slug);
            if (kategoriUrlSlug.isLoaded === true && kategoriUrlSlug.isFound === true) {
                //console.log("Product: dari kategoriUrlSlug : " + kategoriUrlSlug.data.id_return)
                if (kategoriUrlSlug.url_slug === kat_url_slug && subkategoriUrlSlug.url_slug == null) {
                    await this.props.fetchSearchProductByKategoriWithDiskon(
                        kategoriUrlSlug.data.id_return,
                        -1,
                        page-1
                    );
                    //console.log("Product kategori", this.props.resultKategori)
                }
            }
            else {
                //console.log("Ngga ada")
            }
        }
        
    }
    onPaginationChange = async (page) => {
        this.setState({activePage: page});
        const { kat_url_slug, subkat_url_slug } = this.props.match.params;
        if (kat_url_slug && subkat_url_slug) {
            this.loadProductFromPage(page, kat_url_slug, subkat_url_slug);
        }
        else {
            this.loadProductFromPage(page, kat_url_slug, null);
        }
    }
    async componentDidMount() {
        //console.log("Render did mount")
        //console.log("Product: dari didmount")
        this.props.resetKategoriAndSubKategoriUrlSlug();
        window.scrollTo(0,0);
        await this.loadFromApi();
    }
    componentWillUnmount() {
        this.unlisten();
    }
    doRenderKategoriLeftSubMenu() {
        const { listKategoriAll, listSubKategori } = this.props;
        if (listKategoriAll.isLoaded === true) {
            const renderedKategori = listKategoriAll.kategori.map(kategori => {
                var idx = -1;
                for (var x=0;x<listSubKategori.length;x++) {
                    if (listSubKategori[x].id_kategori === kategori.id_return.toString()) {
                        idx = x;
                        break;
                    }
                }

                if (idx >= 0) {
                    const renderedSubKategori = listSubKategori[idx].sub_kategori.map(subkategori => {
                        return (
                            <Menu.Item className="sub_menu" subKatSlug={subkategori.url_slug_return} katSlug={kategori.url_slug_return} onClick={this.handleKategoriSubMenuClick}>
                                {subkategori.nama_pendek_return}
                            </Menu.Item>
                        );
                    });

                    //return submenu
                    return (
                        <Menu.Item>
                            <div style={{cursor: 'pointer'}} onClick={() => {this.handleKategoriMenuClick(kategori.url_slug_return)}}>{kategori.nama_return}</div>
                            <Menu.Menu>
                                {renderedSubKategori}
                            </Menu.Menu>
                        </Menu.Item>
                    );
                }
                else {
                    return <div></div>
                }
            });
            return renderedKategori;
        }
        else {
            return <div>Load kategori..</div>
        }
    }
    doRenderBreadcrumbs() {
        const { kat_url_slug, subkat_url_slug } = this.props.match.params;
        const { kategoriUrlSlug, subkategoriUrlSlug } = this.props;

        if (kat_url_slug && subkat_url_slug) {

            return (
                <Breadcrumb style={{backgroundColor: "#fff"}}>
                    <Breadcrumb.Section link><Link to='/'>Home</Link> </Breadcrumb.Section>
                    <Breadcrumb.Divider />
                    <Breadcrumb.Section link>
                        <Link to='/kategori'>Kategori</Link>
                    </Breadcrumb.Section>
                    <Breadcrumb.Divider />
                    <Breadcrumb.Section link>
                        <Link to={`/kategori/${kategoriUrlSlug.url_slug}`}>
                            {kategoriUrlSlug.data.nama_return}
                        </Link> 
                    </Breadcrumb.Section>
                    <Breadcrumb.Divider />
                    <Breadcrumb.Section active>
                        {subkategoriUrlSlug.data.nama_pendek_return}
                    </Breadcrumb.Section>
                </Breadcrumb>
            );
        }
        else if (kat_url_slug) {
            return (
                <Breadcrumb style={{backgroundColor: "#fff"}}>
                    <Breadcrumb.Section link><Link to='/'>Home</Link></Breadcrumb.Section>
                    <Breadcrumb.Divider />
                    <Breadcrumb.Section link><Link to='/kategori'>Kategori</Link></Breadcrumb.Section>
                    <Breadcrumb.Divider />
                    <Breadcrumb.Section active>
                        {kategoriUrlSlug.data.nama_return}
                    </Breadcrumb.Section>
                </Breadcrumb>
            );
        }
        else {
            return <div></div>
        }
    }
    doRenderKategoriDetail() {
        const { kat_url_slug, subkat_url_slug } = this.props.match.params;
        const { kategoriUrlSlug, subkategoriUrlSlug } = this.props;
        var rendered = <div></div>;

        var titleStr = "";
        var metaDescStr = "";

        if (kat_url_slug && subkat_url_slug) {
            if (kategoriUrlSlug.isLoaded === true && subkategoriUrlSlug.isLoaded === true) {
                if (kategoriUrlSlug.isFound === true && subkategoriUrlSlug.isFound === true) {
                    if (this.props.resultKategori.isLoaded === true && 
                        this.props.resultKategori.id_kategori === kategoriUrlSlug.data.id_return &&
                        this.props.resultKategori.id_sub_kategori === subkategoriUrlSlug.data.id_return) {
                    //if (this.props.resultKategori.isLoaded === true) {    
                        //console.log("Klik halaman dari render:", this.state.activePage)
                        titleStr = "Semua produk " + subkategoriUrlSlug.data.nama_pendek_return + " - Henata"
                        metaDescStr = "Temukan berbagai produk " + subkategoriUrlSlug.data.nama_pendek_return + " di henata dengan harga murah, terjangkau.";
                        
                        rendered = (
                            <div>
                                <Helmet>
                                    <title>{titleStr}</title>
                                    <link rel="alternate" media="only screen and (max-width: 640px)" href={MOBILE_SITE + this.props.location.pathname} />
                                    <meta name="description" content={metaDescStr} />

                                    <meta property="og:title" content={titleStr} />
                                    <meta property="og:type" content="website" />
                                    <meta property="og:description" content={metaDescStr} />
                                    <meta property="og:url" content={DESKTOP_SITE + this.props.location.pathname} />

                                    <meta name="twitter:card" content="summary" />
                                    <meta name="twitter:site" content={TWITTER_SITE} />
                                </Helmet>
                                {this.doRenderBreadcrumbs()}
                                <h3 className="heading-kategori">{subkategoriUrlSlug.data.nama_pendek_return}</h3>
                                <div className="result_keterangan"> Menampilkan hasil {this.props.resultKategori.from}-{this.props.resultKategori.to} dari {this.props.resultKategori.total} </div>
                                <div className="ui grid">
                                    <div className="three wide column">
                                        <Menu style={{width: "100%"}} vertical>
                                            {this.doRenderKategoriLeftSubMenu()}
                                        </Menu>
                                    </div>
                                    <div className="thirteen wide column">
                                        <SearchList products={this.props.resultKategori.products} />
                                        <div className="search_pagination">
                                        <Pagination  total={this.props.resultKategori.total} activeNow={this.state.activePage} onPaginationChange={this.onPaginationChange} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        );
                    }
                    else {
                        rendered = (
                            <div>
                                <div className="ui grid">
                                    <div className="three wide column">
                                        <Menu style={{width: "100%"}} vertical>
                                            {this.doRenderKategoriLeftSubMenu()}
                                        </Menu>
                                    </div>
                                    <div className="thirteen wide column">
                                        Loading barang...
                                    </div>
                                </div>
                            </div>
                        );
                    }
                }
                else {
                    //render 404
                    
                }
            }
            else {
                rendered = (
                    <div>
                        <div className="ui grid">
                            <div className="three wide column">
                                <Menu style={{width: "100%"}} vertical>
                                    {this.doRenderKategoriLeftSubMenu()}
                                </Menu>
                            </div>
                            <div className="thirteen wide column">
                                Loading sekarang...
                            </div>
                        </div>
                    </div>
                );
            }
        }
        else if (kat_url_slug) {
            if (kategoriUrlSlug.isLoaded === true) {
                if (kategoriUrlSlug.isFound === true) {
                    if (this.props.resultKategori.isLoaded === true && this.props.resultKategori.id_kategori === kategoriUrlSlug.data.id_return) {
                    // if (this.props.resultKategori.isLoaded === true && this.props.resultKategori.id_kategori == kategoriUrlSlug.data.id_return) {
                        //console.log("Klik halaman dari render:", this.state.activePage)
                        titleStr = "Semua produk " + kategoriUrlSlug.data.nama_return + " - Henata"
                        metaDescStr = "Temukan berbagai produk " + kategoriUrlSlug.data.nama_return + " di henata dengan harga murah, terjangkau.";

                        rendered = (
                            <div>
                                <Helmet>
                                    <title>{titleStr}</title>
                                    <link rel="alternate" media="only screen and (max-width: 640px)" href={MOBILE_SITE + this.props.location.pathname} />
                                    <meta name="description" content={metaDescStr} />

                                    <meta property="og:title" content={titleStr} />
                                    <meta property="og:type" content="website" />
                                    <meta property="og:description" content={metaDescStr} />
                                    <meta property="og:url" content={DESKTOP_SITE + this.props.location.pathname} />

                                    <meta name="twitter:card" content="summary" />
                                    <meta name="twitter:site" content={TWITTER_SITE} />
                                </Helmet>
                                {this.doRenderBreadcrumbs()}
                                <h3 className="heading-kategori">{kategoriUrlSlug.data.nama_return}</h3>
                                <div className="result_keterangan"> Menampilkan hasil {this.props.resultKategori.from}-{this.props.resultKategori.to} dari {this.props.resultKategori.total} </div>
                                <div className="ui grid">
                                    <div className="three wide column">
                                        <Menu style={{width: "100%"}} vertical>
                                            {this.doRenderKategoriLeftSubMenu()}
                                        </Menu>
                                    </div>
                                    <div className="thirteen wide column">
                                        <SearchList products={this.props.resultKategori.products} />
                                        <div className="search_pagination">
                                        <Pagination  total={this.props.resultKategori.total} activeNow={this.state.activePage} onPaginationChange={this.onPaginationChange} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        );
                    }
                    else {
                        rendered = (
                            <div>
                                <div className="ui grid">
                                    <div className="three wide column">
                                        <Menu style={{width: "100%"}} vertical>
                                            {this.doRenderKategoriLeftSubMenu()}
                                        </Menu>
                                    </div>
                                    <div className="thirteen wide column">
                                        Loading barang...
                                    </div>
                                </div>
                            </div>
                        );
                    }
                }
                else {
                    //render 404
                    
                }
            }
            else {
                rendered = (
                    <div>
                        <div className="ui grid">
                            <div className="three wide column">
                                <Menu style={{width: "100%"}} vertical>
                                    {this.doRenderKategoriLeftSubMenu()}
                                </Menu>
                            </div>
                            <div className="thirteen wide column">
                                Loading sekarang...
                            </div>
                        </div>
                    </div>
                );
            }
        }
        return rendered;
    }
    render() {
        return (
            <div>
                <SearchBar />
                <div className="ui main container">
                    {this.doRenderKategoriDetail()}
                </div>
            </div>
            
        );
    }
}

const mapStateToProps = (state) => {
    return { kategoriUrlSlug: state.kategoriUrlSlug, 
        subkategoriUrlSlug: state.subkategoriUrlSlug, 
        resultKategori: state.searchProductByKategori,
        listKategoriAll: state.listKategoriAll,
        listSubKategori: state.listSubKategori
     }
}
const connectKategoriDetail = connect(mapStateToProps, { 
    fetchKategoriFromUrlSlug, 
    fetchSubKategoriFromUrlSlug,
    fetchKategoriAndSubKategoriUrlSlug, 
    fetchKategoriAndSubKategori,
    fetchSearchProductByKategoriWithDiskon,
    resetKategoriAndSubKategoriUrlSlug })(KategoriDetail);

export { KategoriAll, connectKategoriDetail };
