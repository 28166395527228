import mapis from '../../api/mapis'

export const fetchPoolPengirimanRutinBatch = () => async dispatch => {
    dispatch({
        type: "FETCH_POOL_PENGIRIMAN_RUTIN_BATCH_RESET"
    });
    var url = `/mart/pool/rutin/batch`;
    const response= await mapis.get(url);
    if (response.data.status === "success") {
        dispatch({
            type: "FETCH_POOL_PENGIRIMAN_RUTIN_BATCH_SUCCESS",
            payload: response.data.data
        })
    }
    else {
        dispatch({
            type: "FETCH_POOL_PENGIRIMAN_RUTIN_BATCH_FAILED"
        })
    }
}
export const fetchPoolPengirimanInstant = () => async dispatch => {
    dispatch({
        type: "FETCH_POOL_PENGIRIMAN_INSTANT_RESET"
    })
    var url = `/mart/pool/instant`;
    const response = await mapis.get(url);
    if (response.data.status === "success") {
        dispatch({
            type: "FETCH_POOL_PENGIRIMAN_INSTANT_SUCCESS",
            payload: response.data.data
        })
    }
    else {
        dispatch({
            type: "FETCH_POOL_PENGIRIMAN_INSTANT_FAILED"
        })
    }
}