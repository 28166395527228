const INITIAL_CASHBACK = {
    isLoaded: false,
    result: null
}
const INITIAL_PROMO_DELIVERY = {
    isLoaded: false,
    result: null
}

export const promoCashbackInfo = (state = INITIAL_CASHBACK, action) => {
    switch(action.type) {
        case "FETCH_PROMO_CASHBACK_INFO_RESET":
            return INITIAL_CASHBACK;
        case "FETCH_PROMO_CASHBACK_INFO_SUCCESS":
            return {...state, isLoaded: true, result: action.payload}
        case "FETCH_PROMO_CASHBACK_INFO_FAILED":
            return {...state, isLoaded: true, result: null}
        default:
            return state;
    }
}
export const promoDeliveryInfo = (state = INITIAL_PROMO_DELIVERY, action) => {
    switch(action.type) {
        case "FETCH_PROMO_DELIVERY_INFO_RESET":
            return INITIAL_PROMO_DELIVERY;
        case "FETCH_PROMO_DELIVERY_INFO_SUCCESS":
            return {...state, isLoaded: true, result: action.payload}
        case "FETCH_PROMO_DELIVERY_INFO_FAILED":
            return {...state, isLoaded: true, result: null}
        default:
            return state;
    }
}