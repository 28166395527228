import React from 'react';

class CatatanTambahan extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            catatan_tambahan: "",
            is_modal_catatan_tambahan_open: false
        }
    }
    handleCatatanTambahanInput = (event) => {
        event.preventDefault();
        this.setState({
            catatan_tambahan: event.target.value
        })
    }
    doRenderCatatanTambahan() {
        return (
            <div className="catatan-tambahan">
                <h4 className="heading">Catatan Tambahan (opsional)</h4>
                <div className="ui form" >
                    <textarea placeholder="Masukkan catatan tambahan untuk pesanan ini..." rows="3" value={this.state.catatan_tambahan} onChange={this.handleCatatanTambahanInput} />
                </div>
            </div>
        )
    }
    render() {
        return this.doRenderCatatanTambahan();
    }
}

export default CatatanTambahan;