import React from 'react';
import { Link } from 'react-router-dom';
import "../css/kategori.css"

class SubKategoriList extends React.Component {
    doRenderSubKategori() {
        const { kategori } = this.props;
        return this.props.list.map(subkategori => {
            return (
                <div className="four wide column">
                    <Link className="sub_kategori" to={`../kategori/${kategori.url_slug_return}/${subkategori.url_slug_return}`}>{subkategori.nama_pendek_return}</Link>
                </div>
            );
            
        });
    }
    render () {
        return (
            <div className="ui grid">
                {this.doRenderSubKategori()}
            </div>
        );
    }
}

export default SubKategoriList;