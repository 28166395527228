import mapis from "../../api/mapis";

export const fetchOfficeDetail = () => async dispatch => {
    dispatch({
        type: "FETCH_WAREHOUSE_OFFICE_DETAIL_RESET"
    });
    var url = "/mart/office/detail";
    const response = await mapis.get(url);
    if (response.data.status === "success") {
        dispatch({
            type: "FETCH_WAREHOUSE_OFFICE_DETAIL_SUCCESS",
            payload: response.data.data[0]
        })
    }
    else {
        dispatch({
            type: "FETCH_WAREHOUSE_OFFICE_DETAIL_FAILED"
        })
    }
}