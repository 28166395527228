import React from 'react';
import { Link } from 'react-router-dom';

import history from '../../history';
import "../css/regularbar.css"

class RegularBar extends React.Component {
    render() {
        return (
            <div>
                <div className="ui fixed menu search-menu" ref={(divElement) => {this.divElement = divElement}}>
                    <div className="ui container" >
                        <Link to="/" className="header borderless item" style={{border: "none", borderRight: "none"}}>
                            <img alt="henata" style={{width: '100px'}} className="logo" src="/assets/img/henata_text_only_black.png">
                            </img>
                        </Link>
                        {/* <div className="right borderless item" >
                        <div className="regular-utilities-buttons">
                            <Link to="/apps" className="ui black basic button" style={{width: "150px"}}>Download App</Link>
                        </div>
                        </div> */}
                        

                        {/* <div className="search-holder-utilities-buttons">
                            <Link to="/login" className="ui green button">Masuk</Link>
                        </div>
                        <div className="search-holder-utilities-buttons">
                            <Link to="/register" className="ui red basic button">Daftar</Link>
                        </div> */}
                    </div>
                    
                </div>
                
            </div>
        );
    }
}

export default RegularBar;