import mapis from '../../api/mapis';
import Cookies from 'universal-cookie';

export const fetchUserValidation = (toValidate, valueToCheckObject) => async dispatch => {
    dispatch({
        type: 'FETCH_VALIDATION_RESET'
    });
    var queryObject = {};
    if (toValidate === "email") {
        queryObject.indicator = "email";
        queryObject.val = valueToCheckObject.email ;
    }
    else if (toValidate === "telepon") {
        queryObject.indicator = "telepon";
        queryObject.country = `+${valueToCheckObject.kode_negara}` ;
        queryObject.phone = encodeURIComponent( valueToCheckObject.nomor_ponsel);
    }
    //console.log(queryObject)
    const response = await mapis.get('/mart/member/check', {
        params: queryObject
    });
    if (response.data.status === "success") {
        dispatch({
            type: 'FETCH_VALIDATION_SUCCESS',
            toValidate: toValidate,
            result: response.data.result
        })
    }
    else {
        dispatch({
            type: 'FETCH_VALIDATION_FAILED'
        })
    }
}
export const fetchUserLogin = (userEmail, userPassword) => async(dispatch) => {
    dispatch({
        type: 'FETCH_LOGIN_RESET'
    })
    const response = await mapis.post('/mart/member/login', {
        'login_indicator': 'email',
        'email': userEmail,
        'password': userPassword
    });

    if (response.data.status === "success") {
        var loginPayload = {
            "token": response.data.token,
            "fbase_auth_token": response.data.fbase_auth_token,
            "data": response.data.data
        }

        //3 bulan expired
        var sessionExpired = Math.floor(Date.now() / 1000) + (86400 * 30 * 3);
        var dateNow = new Date();

        const cookies = new Cookies();
        cookies.set("state", loginPayload.token, {
            sameSite: "strict",
            path: "/",
            expires: new Date(dateNow.setMonth(dateNow.getMonth() + 3))
        });
        cookies.set("user", loginPayload.data, {
            sameSite: "strict",
            path: "/",
            expires: new Date(dateNow.setMonth(dateNow.getMonth() + 3))
        })

        // window.localStorage.setItem("exp", sessionExpired.toString());
        // window.localStorage.setItem("id_user", loginPayload.data.id_user_return);
        // window.localStorage.setItem("jenis", loginPayload.data.jenis_return);
        // window.localStorage.setItem("nama_user", loginPayload.data.nama_user_return);
        // window.localStorage.setItem("token", loginPayload.token);

        //console.log(window.localStorage.getItem("nama_user"))

        dispatch({
            type: 'FETCH_LOGIN_SUCCESS',
            payload: loginPayload
        })
    }
    else {
        dispatch({
            type: 'FETCH_LOGIN_FAILED'
        })
    }
}

export const fetchUserRegister = (userObject) => async (dispatch) => {
    dispatch({
        type: 'FETCH_REGISTER_RESET'
    })
    const response = await mapis.post('/mart/member/register', {
        'nama': userObject.nama,
        'email': userObject.email,
        'kode_negara': userObject.kode_negara,
        'telepon': userObject.telepon,
        'password': userObject.password
    })

    if (response.data.status==="success") {
        var userPayload = {
            "id_member": response.data.id_member
        }
        dispatch({
            type: 'FETCH_REGISTER_SUCCESS',
            payload: userPayload
        })
    }
    else {
        dispatch({
            type: 'FETCH_REGISTER_FAILED'
        })
    }
}

//ALAMAT PENGGUNA
export const fetchAlamatMember = (idMember) => async (dispatch) => {
    dispatch({
        type: 'FETCH_MEMBER_ALAMAT_RESET'
    });
    var url = `/mart/member/alamat/all`;

    //console.log("Validate Session", validateSession());
    const cookies = new Cookies();
    if (cookies.get("state")) {
        const response = await mapis.get(url, {
            headers: {
                token: cookies.get("state")
            }
        });
        if (response.data.status === "success") {
            dispatch({
                type: 'FETCH_MEMBER_ALAMAT_SUCCESS',
                payload: response.data.data
            })
        }
        else if (response.data.status === "invalidToken") {
            dispatch({
                type: 'FETCH_SESSION_INVALID_TOKEN'
            })
        }
        else {
            dispatch({
                type: 'FETCH_MEMBER_ALAMAT_FAILED'
            })
        }
    }
    else {
        dispatch({
            type: 'FETCH_SESSION_INVALID_TOKEN'
        })
    }
}

//cek zenny
export const fetchUserZennyAndPoin = () => async (dispatch) => {
    dispatch({
        type: 'FETCH_MEMBER_ZENNY_POIN_RESET'
    })
    var url = `/mart/sercash/poin/info/member`;

    const cookies = new Cookies();
    if (cookies.get("state")) {
        const response = await mapis.get(url, {
            headers: {
                token: cookies.get("state")
            }
        });
        if (response.data.status === "success") {
            var zennyPoinObject = {
                id_member: response.data.id_member,
                sercash_member: response.data.sercash_member,
                poin_member: response.data.poin_member
            }
            dispatch({
                type: 'FETCH_MEMBER_ZENNY_POIN_SUCCESS',
                payload: zennyPoinObject
            })
        }
        else if (response.data.status === "invalidToken") {
            dispatch({
                type: 'FETCH_SESSION_INVALID_TOKEN'
            })
        }
        else {
            dispatch({
                type: 'FETCH_MEMBER_ZENNY_POIN_FAILED'
            })
        }
    }
    else {
        dispatch({
            type: 'FETCH_SESSION_INVALID_TOKEN'
        })
    }
}