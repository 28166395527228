import React from 'react';
import history from '../../history';

import '../css/home.css';

class FeaturedKategoriList extends React.Component {
    routeChange(slug) {
        //masih ngga tau caranya render
        var path = "/kategori";
        if (slug !== "") {
            path = `${path}/${slug}`;
        }

        history.push(path);
    }
    render() {
        return (
            <div className="ui three column stretched grid">
                <div className="column">
                    <div className="ui link card eq-card" onClick={() => {this.routeChange("food/makanan-pokok")}}>
                        {/* <div className="img-parent"> */}
                        <img className="eq-image" src="/assets/img/makanan-pokok-300.png" alt="" />
                        <div className="featured-kat-text">Makanan Pokok</div>
                        {/* </div> */}
                    </div>
                </div>
                <div className="column">
                    <div className="ui link card eq-card" onClick={() => {this.routeChange("food/makanan-ringan")}}>
                        {/* <div className="img-parent"> */}
                            <img className="eq-image" src="/assets/img/snack-300.png" alt=""/>
                            <div className="featured-kat-text">Snack</div>
                        {/* </div> */}
                    </div>
                </div>
                <div className="column">
                    <div className="ui link card eq-card" onClick={() => {this.routeChange("non-food/perawatan-pribadi")}}>
                        {/* <div className="img-parent"> */}
                            <img className="eq-image" src="/assets/img/personal-care-300.png" alt="" />
                            <div className="featured-kat-text">Personal Care</div>
                        {/* </div> */}
                    </div>
                </div>
                <div className="column">
                    <div className="ui link card eq-card" onClick={() => {this.routeChange("food/minuman")}}>
                        <img className="eq-image" src="/assets/img/minuman-300.png" alt=""/>
                        <div className="featured-kat-text">Minuman</div>
                    </div>
                </div>
                <div className="column">
                    <div className="ui link card eq-card" onClick={() => {this.routeChange("food/makanan-instan")}}>
                        <img className="eq-image" src="/assets/img/makanan-instan-300.png" alt=""/>
                        <div className="featured-kat-text">Makanan Instan</div>
                    </div>
                </div>
                <div className="column">
                    <div className="ui link card eq-card" onClick={() => {this.routeChange("")}}>
                        <div className="featured-kat-semua-kategori">Lihat semua kategori</div>
                    </div>
                </div>
            </div>
            
        );
    }
}

export default FeaturedKategoriList;