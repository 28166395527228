import mapis from '../../api/mapis';
import { fetchDiskonByInventarisBatch } from './diskon';
import { JENIS_REGULER, DEFAULT_SIZE_PAGING } from './types';

import _ from 'lodash';

export const fetchSearchProductByKategoriWithDiskon = (id_kategori, id_sub_kategori, page) => async (dispatch, getState) =>{
    dispatch({
        type: 'FETCH_RESET_DISKON_BY_INVENTARIS_BATCH'
    })
    
    await dispatch(fetchSearchProductByKategori(id_kategori, id_sub_kategori, page));
    const listIdBarangInventaris = _.uniq(_.map(getState().searchProductByKategori.products, 'id_barang_inventaris_return'));

    //console.log("ID Inventaris", listIdBarangInventaris);
    listIdBarangInventaris.forEach(async id => {
        await dispatch(fetchDiskonByInventarisBatch(id));
        //console.log("Diskon Inventaris ", getState().diskonByInventarisBatch)
    })
}

export const fetchSearchProductByKeywordWithDiskon = (keyword, page) => async (dispatch, getState) => {
    dispatch({
        type: 'FETCH_RESET_DISKON_BY_INVENTARIS_BATCH'
    })

    await dispatch(fetchSearchProductByKeyword(keyword, page));
    const listIdBarangInventaris = _.uniq(_.map(getState().searchProductByKeyword.products, 'id_barang_inventaris_return'));
    //console.log("Barang inventaris",listIdBarangInventaris)
    listIdBarangInventaris.forEach(async id => {
        await dispatch(fetchDiskonByInventarisBatch(id));
        //console.log("Diskon Inventaris ", getState().diskonByInventarisBatch)
    })
}

export const resetSearchKeywordSuggestion = () => async (dispatch) => {
    dispatch({
        type: "FETCH_SEARCH_SUGGEST_AUTOCOMPLETE_RESET"
    })
}
export const fetchSearchKeywordSuggestion = (keyword) => async (dispatch, getState) => {
    // dispatch({
    //     type: "FETCH_SEARCH_SUGGEST_AUTOCOMPLETE_RESET"
    // })
    const response = await mapis.get('/search/suggest/autocomplete/barangdijual', {
        params: {
            q: keyword
        }
    });
    if (response.data.status==="success") {
        dispatch({
            type: "FETCH_SEARCH_SUGGEST_AUTOCOMPLETE",
            payload: response.data.data
        })
    }
}

export const fetchSearchProductByKeyword = (keyword, page) => async dispatch => {
    var paramsObject = {
        "q": keyword,
        "from": parseInt(page) * DEFAULT_SIZE_PAGING,
        "size": DEFAULT_SIZE_PAGING,
        "jh": JENIS_REGULER
    };
    // dispatch({
    //     type: 'FETCH_SEARCH_PRODUCT_BY_KEYWORD_RESET'
    // });
    const response = await mapis.get('/search/query/barangdijual', {
        params: paramsObject
    })
    if (response.data.status==="success") {
        var from_item = (parseInt(page) * DEFAULT_SIZE_PAGING) + 1;
        var to_item = from_item + DEFAULT_SIZE_PAGING  -1;

        if (to_item > response.data.total) to_item = response.data.total;

        dispatch({
            type: 'FETCH_SEARCH_PRODUCT_BY_KEYWORD',
            payload: {
                total: response.data.total,
                from: from_item,
                to: to_item,
                keyword: keyword,
                data: response.data.data
            }
        })
    }
}
export const fetchSearchProductByKategori = (id_kategori, id_sub_kategori, page) => async dispatch => {
    var paramsObject = {
        "from": parseInt(page) * DEFAULT_SIZE_PAGING,
        "size": DEFAULT_SIZE_PAGING,
        "jh": JENIS_REGULER
    };
    if (id_kategori !== -1) paramsObject.id_kat = id_kategori;
    if (id_sub_kategori !== -1) paramsObject.id_sub_kat = id_sub_kategori;

    // dispatch({
    //     type: 'FETCH_SEARCH_PRODUCT_BY_KATEGORI_RESET'
    // })
    const response = await mapis.get('/search/query/barangdijual/kategori', {
            params: paramsObject
        }
    );
    if (response.data.status==="success") {
        //console.log(response.data);
        var from_item = (parseInt(page) * DEFAULT_SIZE_PAGING) + 1;
        var to_item = from_item + DEFAULT_SIZE_PAGING  -1;

        if (to_item > response.data.total) to_item = response.data.total;

        dispatch({
            type: 'FETCH_SEARCH_PRODUCT_BY_KATEGORI',
            payload: {
                total: response.data.total,
                from: from_item,
                to: to_item,
                id_kategori: id_kategori,
                id_sub_kategori: id_sub_kategori,
                data: response.data.data
            }
        })
    }
};