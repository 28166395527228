import React from 'react';
import NumberFormat from 'react-number-format';
import {Modal, Button} from 'semantic-ui-react';
import {connect} from 'react-redux';

const inlineStyle = {
    modal : {
        position: 'relative',
        height: 'auto',
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  };
class MetodePembayaran extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            metode_pembayaran_terpilih: null,
            is_modal_error_open: false,
            modal_error_message: ""
        }
    }
    onPembayaranZennyClickButton = (event) => {
        event.preventDefault()
        if (this.state.metode_pembayaran_terpilih !== "zenny") {
            var {zenny} = this.props;
            if (zenny < 1000) {
                this.handleModalErrorOpen("Minimum pembayaran dengan menggunakan zenny adalah Rp. 1000")
            }
            else {
                this.setState({
                    metode_pembayaran_terpilih: "zenny"
                })
                this.props.handlePilihMetodePembayaran("zenny");
            }
        }
    }
    onPembayaranTunaiClickButton = (event) => {
        event.preventDefault()
        if (this.state.metode_pembayaran_terpilih !== "tunai") {
            this.setState({
                metode_pembayaran_terpilih: "tunai"
            })
            this.props.handlePilihMetodePembayaran("tunai");
        }
    }
    handleModalErrorOpen = (message) => {
        this.setState({
            is_modal_error_open: true,
            modal_error_message: message
        })
    }
    handleModalErrorClose = () => {
        this.setState({
            is_modal_error_open: false,
            modal_error_message: ""
        })
    }
    doRenderMetodePembayaran() {
        var {isLoaded, zenny, poin} = this.props;
        var {metode_pembayaran_terpilih} = this.state;

        if (isLoaded === true && zenny !== null && poin !== null) {
            var renderButtonZenny, renderButtonTunai;
            if (metode_pembayaran_terpilih) {
                if (metode_pembayaran_terpilih === "zenny") {
                    renderButtonZenny = <img src="/assets/drawables/ic_tick.svg" style={{width: "1.2em"}}></img>
                    renderButtonTunai = <div></div>
                }
                else {
                    renderButtonZenny = <div></div>
                    renderButtonTunai = <img src="/assets/drawables/ic_tick.svg" style={{width: "1.2em"}}></img>
                }
            }
            return (
                <div>
                    <h4 className="heading">Metode Pembayaran</h4>
                    <div className="ui stretched grid">
                        <div className="row" onClick={this.onPembayaranZennyClickButton}>
                            <div className="twelve wide column">
                                <img src="/assets/drawables/ic_zenny_icon.png" style={{width: "48px", height: "auto"}}></img>
                                <div>
                                <NumberFormat value={zenny} displayType={'text'} thousandSeparator={'.'} 
                                decimalSeparator={','} prefix={'Rp'} renderText={ value => <div className="">{value}</div> } />
                                </div>
                            </div>
                            <div className="right aligned four wide column">
                                {renderButtonZenny}
                            </div>
                        </div>
                        <div className="row" onClick={this.onPembayaranTunaiClickButton} >
                            <div className="twelve wide column">
                                Uang Tunai
                            </div>
                            <div className="right aligned four wide column">
                                {renderButtonTunai}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else if (isLoaded === true && zenny == null && poin == null) {
            return (
                <div>
                    <h4 className="heading">Metode Pembayaran</h4>
                    <div>
                        Tidak dapat memuat metode pembayaran.
                    </div>
                </div>
            )
        }
        else {
            return <div></div>
        }
    }
    doRenderModalError() {
        return (
            <Modal
            style={inlineStyle.modal}
            open={this.state.is_modal_error_open} onClose={this.handleModalErrorClose}>
                <Modal.Header>
                    <h4 className="heading">Ups!</h4>
                </Modal.Header>
                <Modal.Content>
                    <div>{this.state.modal_error_message}</div>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='black' basic onClick={this.handleModalErrorClose}>
                        Okay!
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    }
    componentDidUpdate() {
        if (this.state.metode_pembayaran_terpilih == null) {
            var {isLoaded, zenny, poin} = this.props;
            if (isLoaded === true) {
                if (parseInt(zenny) >= 1000) {
                    this.setState({
                        metode_pembayaran_terpilih: "zenny"
                    })
                    this.props.handlePilihMetodePembayaran("zenny")
                }
                else {
                    this.setState({
                        metode_pembayaran_terpilih: "tunai"
                    })
                    this.props.handlePilihMetodePembayaran("tunai")
                }
            }
        }
    }
    render () {
        return (
            <div>
                {this.doRenderMetodePembayaran()}
                {this.doRenderModalError()}
            </div>
        )
        
    }
}
//const mapStateToProps = connect(mapStateToProps)(MetodePembayaran);
export default MetodePembayaran;