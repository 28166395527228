const INITIAL_STATE_LOGIN = {
    isLoginValid: false,
    user: null
}
const INITIAL_STATE_VALIDATION = {
    isValidated: false,
    toValidate: "",
    result: null
}
const INITIAL_STATE_REGISTER = {
    isRegisterValid: false,
    user: null
}
const INITIAL_STATE_MEMBER_ALAMAT_DEFAULT = {
    isLoaded: false,
    alamat: null
}
const INITIAL_STATE_MEMBER_ZENNY_POIN = {
    isLoaded: false,
    result: null
}

export const accountLogin = (state = INITIAL_STATE_LOGIN, action) => {
    switch(action.type) {
        case 'FETCH_LOGIN_RESET':
            return INITIAL_STATE_LOGIN;
        case 'FETCH_LOGIN_SUCCESS':
            return {...state, isLoginValid: true, user: action.payload}
        case 'FETCH_LOGIN_FAILED':
            return {...state, isLoginValid: false, user: null};
        default:
            return state;
    }
}
export const accountRegister = (state = INITIAL_STATE_REGISTER, action) => {
    switch(action.type) {
        case 'FETCH_REGISTER_RESET':
            return INITIAL_STATE_REGISTER;
        case 'FETCH_REGISTER_SUCCESS':
            return {...state, isRegisterValid: true, user: action.payload}
        case 'FETCH_REGISTER_FAILED':
            return {...state, isRegisterValid: false, user: null}
        default:
            return state;
    }
}
export const accountValidation = (state = INITIAL_STATE_VALIDATION, action) => {
    switch(action.type) {
        case 'FETCH_VALIDATION_RESET':
            return INITIAL_STATE_VALIDATION;
        case 'FETCH_VALIDATION_SUCCESS':
            return {...state, isValidated: true, toValidate: action.toValidate, result: action.result}
        case 'FETCH_VALIDATION_FAILED':
            return {...state, isValidated: false, toValidate: "", result: null}
        default:
            return state;
    }
}

//alamat
export const memberAlamatAll = (state = INITIAL_STATE_MEMBER_ALAMAT_DEFAULT, action) => {
    switch(action.type) {
        case 'FETCH_MEMBER_ALAMAT_RESET':
            return INITIAL_STATE_MEMBER_ALAMAT_DEFAULT;
        case 'FETCH_MEMBER_ALAMAT_SUCCESS':
            return {...state, isLoaded: true, alamat: action.payload};
        case 'FETCH_MEMBER_ALAMAT_FAILED':
            return {...state, isLoaded: true, alamat: null};
        default:
            return state;
    }
}

//zenny
export const memberZennyPoin = (state = INITIAL_STATE_MEMBER_ZENNY_POIN, action) => {
    switch(action.type) {
        case 'FETCH_MEMBER_ZENNY_POIN_RESET':
            return INITIAL_STATE_MEMBER_ZENNY_POIN;
        case 'FETCH_MEMBER_ZENNY_POIN_SUCCESS':
            return {...state, isLoaded: true, result: action.payload}
        case 'FETCH_MEMBER_ZENNY_POIN_FAILED':
            return {...state, isLoaded: true, result: null}
        default:
            return state;
    }
}