import React from 'react';
import { fetchProductByLinimasaIdWithDiskon, fetchLinimasaInfo } from '../actions/linimasa';

import { Helmet } from 'react-helmet';
import { MOBILE_SITE } from '../actions/types'
import { connect } from 'react-redux'
//import SearchBar from '../universal/SearchBar';
import { connectSearchPanel as SearchBar} from '../universal/SearchBar';

import { titleCase } from '../actions/types';

import SearchList from '../search/SearchList';


class Linimasa extends React.Component {
    async loadFromApi() {
        await this.props.fetchLinimasaInfo(this.props.match.params.id);
        await this.props.fetchProductByLinimasaIdWithDiskon(this.props.match.params.id);
    }
    componentDidMount() {
        if (this.props.linimasaInfo.isLoaded === true) {
            if (this.props.linimasaInfo.data.id_return !== this.props.match.params.id &&
                this.props.listProduct.isLoaded === true && this.props.listProduct.linimasa !== this.props.match.params.id) {
                window.scrollTo(0,0);
    
                this.loadFromApi();
            }
        }
        else {
            window.scrollTo(0,0);
    
            this.loadFromApi();
        }
    }
    doRenderHeader() {
        if (this.props.linimasaInfo.isLoaded === true) {
            var judulStr = titleCase(this.props.linimasaInfo.data.title_return) + " - Henata Promo";
            return (
                <Helmet>
                    <title>{judulStr}</title>
                    <link rel="alternate" media="only screen and (max-width: 640px)" href={MOBILE_SITE + this.props.location.pathname} />
                </Helmet>
            );
        }
        else {
            return (
                <Helmet>
                    <title></title>
                </Helmet>
            )
        }
    }
    doRenderInfo() {
        const { linimasaInfo } = this.props;
        var rendered = <div></div>
        if (linimasaInfo.isLoaded === true && linimasaInfo.isFound === true) {
            rendered = (
                <div className="linimasa-info">
                    <h3>{linimasaInfo.data.title_return}</h3>
                    <p>{linimasaInfo.data.deskripsi_return}</p>
                </div>
            );
        }
        else {
            rendered = <div></div>
        }
        return rendered;
    }
    doRenderProducts() {
        const { listProduct } = this.props;
        var rendered = <div></div>
        if (listProduct.isLoaded === true && listProduct.linimasa === this.props.match.params.id) {
            rendered = (
                <div className="ui grid">
                    {/* <div className="three wide column">
                        
                    </div> */}
                    <div className="sixteen wide column">
                        <SearchList products={listProduct.products} />
                    </div>
                </div>
            );
        }
        else {
            rendered = (
                <div>Loading...</div>
            )
        }
        return rendered;
    }
    render() {
        return (
            <div>
                {this.doRenderHeader()}
                <SearchBar />
                <div className="ui main container">
                    {this.doRenderInfo()}
                    {this.doRenderProducts()}
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return { listProduct: state.listProductByLinimasaId, linimasaInfo: state.linimasaInfo }
}
const connectLinimasa = connect(mapStateToProps, {fetchLinimasaInfo,fetchProductByLinimasaIdWithDiskon})(Linimasa)
export { connectLinimasa }