import mapis from "../../api/mapis";

export const fetchBiayaKirim = (harga_barang, distanceDalamMeter) => async dispatch => {
    dispatch({
        type: "FETCH_BIAYA_KIRIM_RESET"
    });
    var url = '/mart/delivery/calculate'
    const response = await mapis.get(url, {
        params: {
            price: harga_barang,
            dist: distanceDalamMeter
        }
    });
    if (response.data.status==="success") {
        var deliveryObject = {
            id_jenis_delivery_return: response.data.id_jenis_delivery_return,
            price_per_km_return: response.data.price_per_km_return,
            distance_in_km_return: response.data.distance_in_km_return,
            delivery_price_return: response.data.delivery_price_return,
            instant_delivery_price_return: response.data.instant_delivery_price_return
        }
        dispatch({
            type: "FETCH_BIAYA_KIRIM_SUCCESS",
            payload: deliveryObject
        })
    }
    else {
        dispatch({
            type: "FETCH_BIAYA_KIRIM_FAILED"
        })
    }
}