import React from 'react';
import {Helmet} from 'react-helmet';
import {connect} from 'react-redux';
import {fetchBarangDijualDariDraft} from '../actions/product';
import Product from '../page_components/Troli/Product'
import { connectSearchPanel as SearchBar} from '../universal/SearchBar';
import {Modal, Button, Icon} from 'semantic-ui-react';
import RingkasanBelanja from '../page_components/Troli/RingkasanBelanja'

import  '../css/Troli/troli.css';
import Cookies from 'universal-cookie';

const inlineStyle = {
    modal : {
        position: 'relative',
        height: 'auto',
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  };

class Troli extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            barang_dijual : [],
            diskon: [],
            pesanan: [],

            //modal untuk delete
            is_modal_delete_product_open: false,
            id_barang_dijual_to_delete: ""
        }
        this.cookies = new Cookies();
    }
    doRenderHeader() {
        var titleStr = "Troli Belanja - Henata";
        return (
            <Helmet>
                <title>{titleStr}</title>
            </Helmet>
        )
    }
    //modal controlled
    handleModalDeleteProductOpen = (id_barang_dijual) => {
        this.setState({
            is_modal_delete_product_open: true,
            id_barang_dijual_to_delete: id_barang_dijual
        })
    }
    handleModalDeleteProductClose = () => {
        this.setState({
            is_modal_delete_product_open: false,
            id_barang_dijual_to_delete: ""
        })
    }
    handleModalDeleteProductCloseAndDelete = () => {
        this.onDeleteProduct(this.state.id_barang_dijual_to_delete);
    }
    //--
    async loadDraftBatch() {
        if (window.localStorage.getItem("troli") && this.cookies.get("user")) {
            //console.log("Cookies", this.cookies.get("user"));
        //console.log("Troli", window.localStorage.getItem("troli"))
            var troliArray = JSON.parse(window.localStorage.getItem("troli"));
            if (troliArray.length > 0) {
                var arrayInventaris = troliArray.map(element => element.id_barang_inventaris);
                var bodyObject = {
                    "array_inventaris": arrayInventaris,
                    "id_jenis_harga": this.cookies.get("user").jenis_return.toString()
                }
                //console.log(bodyObject)
                await this.props.fetchBarangDijualDariDraft(bodyObject);
                this.setState({
                    barang_dijual: this.props.productDraftBatch.barang_dijual,
                    diskon: this.props.productDraftBatch.diskon,
                    pesanan: troliArray
                })
            }
        }
    }
    onChangeQuantity = (id_barang_dijual, jumlah_pesanan_baru) => {
        //console.log("Onchangequantity", id_barang_dijual + " --> " + jumlah_pesanan_baru)
        var pesananArray = [...this.state.pesanan];
        var pesananObject = pesananArray.find(element => {
            return element.id_barang_dijual === id_barang_dijual;
        });
        if (pesananObject) {
            if (jumlah_pesanan_baru > 0) {
                pesananObject.jumlah_order = jumlah_pesanan_baru;
                this.setState({
                    pesanan: pesananArray
                })
                window.localStorage.removeItem("troli");
                window.localStorage.setItem("troli", JSON.stringify(pesananArray));
            }
        }
    }
    onDeleteProduct = (id_barang_dijual) => {
        var pesananArray = [...this.state.pesanan];
        var pesananIdx = pesananArray.findIndex(element => {
            return element.id_barang_dijual === id_barang_dijual
        })
        //console.log("Deleting...", pesananIdx)
        if (pesananIdx != -1) {
            pesananArray.splice(pesananIdx, 1);
            this.setState({
                pesanan: pesananArray,
                is_modal_delete_product_open: false,
                id_barang_dijual_to_delete: ""
            })
            window.localStorage.removeItem("troli");
            window.localStorage.setItem("troli", JSON.stringify(pesananArray))
        }
    }
    componentDidMount() {
        if (this.cookies.get("user")) {
            this.loadDraftBatch();
        }
        else {
            window.location.replace('/');
        }
    }
    doRenderModal() {
        var barangDijualToDelete, modalMessage;
        
        if (this.state.is_modal_delete_product_open === true && this.state.id_barang_dijual_to_delete !== "") {
            barangDijualToDelete = this.state.barang_dijual.find(element => {
                return element.id_barang_return === this.state.id_barang_dijual_to_delete
            })
        }
        if (barangDijualToDelete) {
            modalMessage = (
                <div>
                    <span>Apakah kakak yakin akan menghapus </span>
                        <span >{barangDijualToDelete.nama_barang_return}</span>
                        <span> dari daftar belanja kakak?</span>
                </div>
            )
        }
        else {
            modalMessage = (
                <div>Tidak ada barang yang dipilih.</div>
            )
        }
        
        
        return (
            <Modal
                size="tiny"
                style={inlineStyle.modal}
                open={this.state.is_modal_delete_product_open}
                onClose={this.handleModalDeleteProductClose}>
                <Modal.Content >
                    {modalMessage}
                </Modal.Content>
                <Modal.Actions>
                    <Button color='green' onClick={this.handleModalDeleteProductCloseAndDelete}>
                        Ya
                    </Button>
                    <Button color='red' onClick={this.handleModalDeleteProductClose}>
                        Tidak
                    </Button>
                </Modal.Actions>
            </Modal>

            // <ProductDeleteModal isOpen={this.state.is_modal_delete_product_open} message={modalMessage} />
        )
        
    }
    doCalculateSubtotalBeforeDiscount = () => {
        const {barang_dijual, pesanan, diskon} = this.state;
        var hargaTotal = 0, hargaSubtotal = 0, hargaDiskon = 0;
        for (var x=0;x<pesanan.length;x++) {
            var hargaPerBarang = 0, diskonValuePerBarang = 0, diskonPersenPerBarang = 0;
            var barangDijualElement = barang_dijual.find(element => {
                return element.id_barang_return === pesanan[x].id_barang_dijual;
            });
            var diskonElement = diskon.find(element => {
                return element.id_barang_inventaris_return === barangDijualElement.id_barang_inventaris_return &&
                    element.jenis_pelanggan_return === barangDijualElement.id_jenis_harga_return;
            });
            // for (var y=0;y< barang_dijual.length; y++) {
            //     if (barang_dijual[y].id_barang_return === pesanan[x].id_barang_dijual) {
            //         hargaPerBarang += (barang_dijual[y].harga_barang_return * pesanan[x].jumlah_order)
            //     }
            // }
            if (barangDijualElement) {
                hargaPerBarang = (barangDijualElement.harga_barang_return * pesanan[x].jumlah_order)
            }
            if (diskonElement) {
                if (diskonElement.min_pembelian_return == -1 && diskonElement.max_pembelian_return == -1) {
                    diskonValuePerBarang = (Number(pesanan[x].jumlah_order) * Number(diskonElement.diskon_value_return));
                    diskonPersenPerBarang = (Number(diskonElement.diskon_persen_return) * hargaPerBarang) / 100;
                }
                else if (diskonElement.min_pembelian_return == -1 && diskonElement.max_pembelian_return > 0) {
                    if (pesanan[x].jumlah_order <= diskonElement.max_pembelian_return) {
                        diskonValuePerBarang = (Number(pesanan[x].jumlah_order) * Number(diskonElement.diskon_value_return));
                        diskonPersenPerBarang = (Number(diskonElement.diskon_persen_return) * hargaPerBarang) / 100;
                    }
                }
                else if (diskonElement.min_pembelian_return > 0 && diskonElement.max_pembelian_return == -1) {
                    if (pesanan[x].jumlah_order >= diskonElement.min_pembelian_return) {
                        diskonValuePerBarang = (Number(pesanan[x].jumlah_order) * Number(diskonElement.diskon_value_return));
                        diskonPersenPerBarang = (Number(diskonElement.diskon_persen_return) * hargaPerBarang) / 100;
                    }
                }
                else if (diskonElement.min_pembelian_return > 0 && diskonElement.max_pembelian_return > 0) {
                    if (pesanan[x].jumlah_order <= diskonElement.max_pembelian_return && pesanan[x].jumlah_order >= diskonElement.min_pembelian_return) {
                        diskonValuePerBarang = (Number(pesanan[x].jumlah_order) * Number(diskonElement.diskon_value_return));
                        diskonPersenPerBarang = (Number(diskonElement.diskon_persen_return) * hargaPerBarang) / 100;
                    }
                }
            }

            var hargaFinalPerBarang = hargaPerBarang - diskonValuePerBarang - diskonPersenPerBarang;
            hargaSubtotal += hargaPerBarang;
            hargaDiskon += (diskonValuePerBarang + diskonPersenPerBarang);
            //console.log("Diskon", hargaDiskon);

            hargaTotal += hargaFinalPerBarang;
        }
        return {
            "harga_total_final": hargaTotal,
            "harga_subtotal": hargaSubtotal,
            "harga_diskon": hargaDiskon 
        }
    }
    doRenderPage() {
        const pesanan = [...this.state.pesanan];
        const { barang_dijual, diskon} = this.state;
        if ( barang_dijual && diskon && barang_dijual.length !== 0) {

            var renderedTroliList;
            
            renderedTroliList = barang_dijual.map( element => {
                var diskonElement = diskon.find( diskonElement => {
                    return diskonElement.id_barang_inventaris_return === element.id_barang_inventaris_return &&
                    diskonElement.jenis_pelanggan_return === element.id_jenis_harga_return;
                })
                var pesananElement = pesanan.find(pesananElement => {
                    return pesananElement.id_barang_dijual === element.id_barang_return
                })
                //console.log("pesanan", pesananElement);
                //console.log("Elemen pesanan", pesananElement)
                if (pesananElement) {
                    if (diskonElement) {
                        return (
                            <Product barang={element} diskon={diskonElement} pesanan={pesananElement} handleModalDeleteProductOpen={this.handleModalDeleteProductOpen} onChangeQuantity={this.onChangeQuantity} />
                        )
                    }
                    else {
                        return (
                            <Product barang={element} pesanan={pesananElement} handleModalDeleteProductOpen={this.handleModalDeleteProductOpen} onChangeQuantity={this.onChangeQuantity}  />
                        )
                    }
                }
                else {
                    return <div></div>
                }
                
            });

            var ringkasanBelanjaObj = this.doCalculateSubtotalBeforeDiscount();
            //console.log("Harga total", ringkasanBelanjaObj)
            return (
                <div className="ui main container">
                    <div className="ui grid">
                        {this.doRenderModal()}
                        <div className="eleven wide column">
                            <h3 className="heading">Troli Belanja</h3>
                            <div className="ui items">
                            {renderedTroliList}
                            </div>
                        </div>
                        <div className="five wide column">
                            <h3 className="heading">Ringkasan Belanja</h3>
                            <RingkasanBelanja ringkasan={ringkasanBelanjaObj} />
                        </div>
                    </div>
                    
                </div>
            );
        }
        else {
            return <div></div>
        }
    }
    render() {
        return (
            <div>
                {this.doRenderHeader()}
                <SearchBar />
                {this.doRenderPage()}
            </div>
        )
    }
}
const productMapStateToProps = (state) => {
    return {
        productDraftBatch: state.productDraftBatch
    }
}
const connectTroli = connect(productMapStateToProps, {fetchBarangDijualDariDraft})(Troli);
export { connectTroli };