import React from 'react';
import SearchItem from './SearchItem';

class SearchList extends React.Component {
    // async loadFromApi(page) {
    //     if (this.props.searchFrom === "kategori_page") {
    //         //akses API kategori
    //         const { idKategori, idSubKategori } = this.props;
    //         await this.props.fetchSearchProductByKategori(idKategori, idSubKategori, (parseInt(page) - 1));
    //     }
    //     else if (this.props.searchFrom === "keyword_page") {
    //         //akses API keyword
    //     }
    // }
    componentDidMount() {
        window.scrollTo(0,0 );
        //console.log("SearchList Did mount...")
        //this.loadFromApi(this.props.pageNum);
    }
    doRenderSearchResult() {
        const { products } = this.props;
        const renderedSearch = products.map(product => {
            return <SearchItem key={product.id_barang_return} item={product} />
       })

       return (
           <div>
               {/* <div style={{marginBottom: "10px"}}>Terdapat {total} barang.</div> */}
               {/* <div className="ui five doubling link cards" >
                   {renderedSearch}
               </div> */}
               <div className="ui five column grid" >
                   {renderedSearch}
               </div>
           </div>
       );
        // if (this.props.searchFrom === "kategori_page") {
        //     const { isLoaded, total, products } = this.props.resultKategori;
        //     if (isLoaded === true) {
        //         const renderedSearch = products.map(product => {
        //              return <SearchItem key={product.id_barang_return} item={product} />
        //         })

        //         return (
        //             <div>
        //                 <div style={{marginBottom: "10px"}}>Terdapat {total} barang.</div>
        //                 <div className="ui five doubling link cards" >
        //                     {renderedSearch}
        //                 </div>
        //             </div>
        //         );
        //     }
        // }
        // else if (this.props.searchFrom === "keyword_page") {

        // }
    }
    
    render() {
        //console.log(this.state.activePage);
        return (
            <div>
                {this.doRenderSearchResult()}
            </div>
        );
    }
}
export default SearchList;