const INITIAL_TRANSAKSI_BARU = {
    isLoaded: false,
    total_harga_barang: null
}

export const insertTransaksiBaru = (state = INITIAL_TRANSAKSI_BARU, action) => {
    switch(action.type) {
        case "FETCH_INSERT_TRANSAKSI_RESET":
            return INITIAL_TRANSAKSI_BARU;
        case "FETCH_INSERT_TRANSAKSI_SUCCESS":
            return {...state, isLoaded: true, total_harga_barang: action.payload}
        case "FETCH_INSERT_TRANSAKSI_FAILED":
            return {...state, isLoaded: true, total_harga_barang: null}
        default:
            return state;
    }
}