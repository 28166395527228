import React from 'react';

import history from '../../../history';

class UtilitiesMenu extends React.Component {
    onTroliClick = (event) => {
        event.preventDefault();

        let path = '/troli';
        history.push(path);
    }
    render() {
        return (
            <div className="ui text menu">
                <a onClick={this.onTroliClick} className="borderless item" >
                <img style={{width:"24px", height: "24px"}} src="/assets/drawables/shopping-basket.svg"  />
                </a>
            </div>
        )        
    }
}

export default UtilitiesMenu;