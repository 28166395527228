import mapis from '../../api/mapis';
import { fetchDiskonByInventarisBatch } from './diskon';
import { JENIS_REGULER } from './types';

import _ from 'lodash';


export const resetProductByLinimasaId = () => async dispatch => {
    dispatch({
        type: 'FETCH_PRODUCT_BY_LINIMASA_RESET'
    })
}
export const fetchProductByLinimasaIdWithDiskon = (id_linimasa) => async (dispatch, getState) => {
    dispatch({
        type: 'FETCH_PRODUCT_BY_LINIMASA_RESET'
    });
    dispatch({
        type: 'FETCH_RESET_DISKON_BY_INVENTARIS_BATCH'
    });
    await dispatch(fetchProductByLinimasaId(id_linimasa));
    const listIdBarangInventaris = _.uniq(_.map(getState().listProductByLinimasaId.products, "id_barang_inventaris_return"));

    listIdBarangInventaris.forEach(async id => {
        await dispatch(fetchDiskonByInventarisBatch(id))
    })
}
export const fetchLinimasaInfo = (id_linimasa) => async dispatch => {
    dispatch({
        type: "FETCH_LINIMASA_INFO_RESET"
    })
    const response = await mapis.get("/mart/linimasa/info", {
        params: {
            id: id_linimasa
        }
    });
    if (response.data.status === "success") {
        if (response.data.data.length >= 1) {
            dispatch({
                type: "FETCH_LINIMASA_INFO",
                payload: response.data.data[0]
            })
        }
        else {
            dispatch({
                type: "FETCH_LINIMASA_INFO_NOTFOUND"
            })
        }
    }
}
export const fetchProductByLinimasaId = (id_linimasa) => async dispatch => {
    var paramsObject = {
        id_linimasa: id_linimasa,
        jenis: JENIS_REGULER
    }
    const response = await mapis.get("/mart/barang/dijual/filter", {
        params: paramsObject
    });
    if (response.data.status==="success") {
        dispatch({
            type: 'FETCH_PRODUCT_BY_LINIMASA',
            id_linimasa: id_linimasa,
            payload: response.data.data
        })
    }
}