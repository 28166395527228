import mapis from "../../api/mapis"
import Cookies from "universal-cookie";

export const fetchPromoCashbackInfo = () => async dispatch => {
    dispatch({
        type: "FETCH_PROMO_CASHBACK_INFO_RESET"
    })
    const cookies = new Cookies();
    if (cookies.get("user")) {
        const response = await mapis.get("/mart/cashback", {
            params: {
                jenis: cookies.get("user").jenis_return
            }
        });
        if (response.data.status === "success") {
            dispatch({
                type: "FETCH_PROMO_CASHBACK_INFO_SUCCESS",
                payload: response.data.data
            })
        }
        else {
            dispatch({
                type: "FETCH_PROMO_CASHBACK_INFO_FAILED"
            })
        }
    }
    else {
        dispatch({
            type: "FETCH_PROMO_CASHBACK_INFO_FAILED"
        })
    }    
}
export const fetchPromoDeliveryInfo = (nama_tipe_pool) => async dispatch => {
    dispatch({
        type: "FETCH_PROMO_DELIVERY_INFO_RESET"
    });
    const cookies = new Cookies();
    if (cookies.get("user")) {
        var url = `/mart/promotion/read/nama_jenis/${nama_tipe_pool}/${cookies.get("user").jenis_return}`;
        const response = await mapis.get(url);
        if (response.data.status === "success") {
            dispatch({
                type: "FETCH_PROMO_DELIVERY_INFO_SUCCESS",
                payload: response.data.data
            })
        }
        else {
            dispatch({
                type: "FETCH_PROMO_DELIVERY_INFO_FAILED"
            })
        }
    }
    else {
        dispatch({
            type: "FETCH_PROMO_DELIVERY_INFO_FAILED"
        })
    }
}