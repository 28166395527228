import React from 'react';
import {connect} from 'react-redux';
import {fetchPoolPengirimanInstant, fetchPoolPengirimanRutinBatch} from '../../actions/pool_pengiriman';
import {Modal} from 'semantic-ui-react';

import '../../css/Konfirmasi/konfirmasi.css'

const inlineStyle = {
    modal : {
        position: 'relative',
        height: 'auto',
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  };
class JadwalPengiriman extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pool_pengiriman_terpilih: null,
            day_pengiriman_terpilih: null,
            date_pengiriman_terpilih: null,
            is_modal_pool_pengiriman_open: false
        }
    }
    loadPoolPengiriman = async () => {
        await this.props.fetchPoolPengirimanInstant();
        await this.props.fetchPoolPengirimanRutinBatch();
    }
    onPengirimanRutinButtonClick = (day, date, id_pool_pengiriman) => {
        var {poolPengirimanRutinAll} = this.props;
        if (poolPengirimanRutinAll.isLoaded === true && poolPengirimanRutinAll.pengiriman ) {
            var poolPengirimanObject;
            // for (var x=0;x<poolPengirimanRutinAll.pengiriman.length;x++) {
            //     for (var y=0;y<poolPengirimanRutinAll.pengiriman[x].jadwal;y++) {
            //         if (poolPengirimanRutinAll.pengiriman[x].jadwal[y].id_return === id_pool_pengiriman) {
            //             poolPengirimanObject = poolPengirimanRutinAll.pengiriman[x].jadwal[y];
            //             break;
            //         }
            //     }
            // }
            poolPengirimanRutinAll.pengiriman.some(element => {
                if (poolPengirimanObject) return false;
                poolPengirimanObject = element.jadwal.find(elementJadwal => {
                    return elementJadwal.id_return == id_pool_pengiriman
                });
            })
            if (poolPengirimanObject) {
                this.setState({
                    day_pengiriman_terpilih: day,
                    date_pengiriman_terpilih: date,
                    pool_pengiriman_terpilih: poolPengirimanObject,
                    is_modal_pool_pengiriman_open: false
                });
                this.props.handlePoolPengiriman(day, date, poolPengirimanObject);
            }
        }
    }
    onPengirimanInstantButtonClick = (day, date, id_pool_pengiriman) => {
        if (this.props.poolPengirimanInstantAll.isLoaded === true && this.props.poolPengirimanInstantAll.pengiriman ) {
            var poolPengirimanObject = this.props.poolPengirimanInstantAll.pengiriman.find(element => {
                return element.id_return === id_pool_pengiriman
            })
            if (poolPengirimanObject) {
                this.setState({
                    day_pengiriman_terpilih: day,
                    date_pengiriman_terpilih: date,
                    pool_pengiriman_terpilih: poolPengirimanObject,
                    is_modal_pool_pengiriman_open: false
                })
                this.props.handlePoolPengiriman(day, date, poolPengirimanObject);
            }
        }
    }
    handleModalPoolPengirimanOpen = () => {
        this.loadPoolPengiriman();
        this.setState({
            is_modal_pool_pengiriman_open: true
        })
    }
    handleModalPoolPengirimanClose = () => {
        this.setState({
            is_modal_pool_pengiriman_open: false
        })
    }
    doRenderPoolPengirimanInstant() {
        var { poolPengirimanInstantAll } = this.props;
        if (poolPengirimanInstantAll) {
            if (poolPengirimanInstantAll.isLoaded === true && poolPengirimanInstantAll.pengiriman !== null) {
                var renderedInstant = poolPengirimanInstantAll.pengiriman.map(element => {
                    if (element.is_pool_valid_return === true) {
                        return (
                            <div className="item">
                                <div className="middle aligned content">
                                    <div className="ui grid">
                                        <div className="fourteen wide column">
                                            <p>Priority Delivery</p>
                                            <p>Pengiriman barang instan saat ini juga.</p>
                                            <p>Tersedia!</p>
                                        </div>
                                        <div className="two wide column">
                                            <button onClick={() => {this.onPengirimanInstantButtonClick(element.day_formatted_return, element.date_formatted_return, element.id_return)}} className="ui teal button">Pilih</button>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        )
                    }
                    else {
                        return (
                            <div className="item">
                                <div className="middle aligned content">
                                    <div className="ui grid">
                                        <div className="fourteen wide column">
                                        <p>Priority Delivery</p>
                                        <p>Pengiriman barang instan saat ini juga.</p>
                                        <p>{`Hanya tersedia pukul ${element.jam_mulai_formatted_return} - ${element.jam_selesai_formatted_return}`}</p>
                                        </div>
                                        <div className="two wide column">
                                            <button className="ui disabled button">Pilih</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                });
                return (
                    <div className="pool-pengiriman-instant">
                        <h4 className="heading">Pengiriman Instan</h4>
                        <div className="ui items">
                            {renderedInstant}
                        </div>
                    </div>
                )
            }
            else {
                return <div></div>
            }
        }
        else {
            return <div></div>
        }
    }
    doRenderPoolPengirimanRutin() {
        var { poolPengirimanRutinAll } = this.props;
        if (poolPengirimanRutinAll) {
            if (poolPengirimanRutinAll.isLoaded === true && poolPengirimanRutinAll.pengiriman !== null) {
                var renderedRutin = poolPengirimanRutinAll.pengiriman.map(element => {
                    var renderedJadwal = element.jadwal.map(elementJadwal => {
                        var sisaPool = parseInt(elementJadwal.maksimum_entry_return) - parseInt(elementJadwal.entry_now_return);
                        if (sisaPool > 0) {
                            if (elementJadwal.is_pool_valid_return === true) {
                                return (
                                    <div className="item">
                                        <div className="middle aligned content">
                                            <div className="ui grid">
                                                <div className="fourteen wide column">
                                                    <p>{`${elementJadwal.jam_mulai_formatted_return} - ${elementJadwal.jam_selesai_formatted_return}`}</p>
                                                    <p>{`Tersisa ${sisaPool} slots.`}</p>
                                                </div>
                                                <div className="two wide column">
                                                    <button onClick={() => {this.onPengirimanRutinButtonClick(element.day, element.date, elementJadwal.id_return)}} className="ui green button">Pilih</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            else {
                                return (
                                    <div className="item">
                                        <div className="middle aligned content">
                                            <div className="ui grid">
                                                <div className="fourteen wide column">
                                                    <p>{`${elementJadwal.jam_mulai_formatted_return} - ${elementJadwal.jam_selesai_formatted_return}`}</p>
                                                    <p>{`Tidak tersedia.`}</p>
                                                </div>
                                                <div className="two wide column">
                                                    <button className="ui disabled button">Pilih</button>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                )
                            }
                        }
                        else {
                            return (
                                <div className="item">
                                    <div className="middle aligned content">
                                        <div className="ui grid">
                                            <div className="fourteen wide column">
                                                <p>{`${elementJadwal.jam_mulai_formatted_return} - ${elementJadwal.jam_selesai_formatted_return}`}</p>
                                                <p>{`Tidak ada slot sisa.`}</p>
                                            </div>
                                            <div className="two wide column">
                                                <button className="ui disabled button">Pilih</button>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            )
                        }
                    });
                    return (
                        <div style={{marginTop: "1em"}}>
                            <h4 className="day">{element.day}</h4>
                            <div className="ui items">
                                {renderedJadwal}
                            </div>
                        </div>
                    )
                });
                return (
                    <div className="pool-pengiriman-reguler">
                        <h4 className="heading">Pengiriman Reguler</h4>
                        {renderedRutin}
                    </div>
                )
            }
            else {
                return <div></div>
            }
        }
        else {
            return <div></div>
        }
    }
    doRenderModalPoolPengiriman() {
        return (
            <Modal
            open={this.state.is_modal_pool_pengiriman_open}
            onClose={this.handleModalPoolPengirimanClose}
            style={inlineStyle.modal}>
                <Modal.Header>
                <h3 className="heading">Pengiriman</h3>
                </Modal.Header>
                <Modal.Content>
                    
                    <div>
                        {this.doRenderPoolPengirimanInstant()}
                        {this.doRenderPoolPengirimanRutin()}
                    </div>
                </Modal.Content>
            </Modal>
        )
    }
    doRenderPoolPengirimanTerpilih() {
        var jsxtorender;
        if (this.state.pool_pengiriman_terpilih && this.state.day_pengiriman_terpilih) {
            var pengirimanString;

            if (this.state.pool_pengiriman_terpilih.nama_tipe_pool_return == "INSTANT_POOL") {
                pengirimanString = <div>{`${this.state.day_pengiriman_terpilih}, dikirim dengan pengiriman instant.`}</div>
            }
            else {
                pengirimanString = <div>{`${this.state.day_pengiriman_terpilih}, (${this.state.pool_pengiriman_terpilih.jam_mulai_formatted_return} - ${this.state.pool_pengiriman_terpilih.jam_selesai_formatted_return})`}</div>
            }
            jsxtorender = (
                <div>
                    <h4 className="heading">Jadwal Pengiriman</h4>
                    <div className="ui segment" style={{cursor: "pointer"}} onClick={this.handleModalPoolPengirimanOpen}>
                        {pengirimanString}
                    </div>
                </div>
            )
        }
        else {
            jsxtorender = (
                <div>
                    <h4 className="heading">Jadwal Pengiriman</h4>
                    <div className="ui segment" style={{cursor: "pointer"}} onClick={this.handleModalPoolPengirimanOpen}>
                        <p>Pilih jadwal pengiriman</p>
                    </div>
                </div>
            )
        }
        return jsxtorender;
    }
    componentDidMount() {
        console.log("did mount");
    }
    componentDidUpdate() {
        console.log("did update")
    }
    render() {
        console.log("Render ulang", this.state);
        return (
            <div>
                {this.doRenderPoolPengirimanTerpilih()}
                {this.doRenderModalPoolPengiriman()}
            </div>
        )
    }
}

const mapStateToProps = (state ) => {
    return {
        poolPengirimanRutinAll: state.poolPengirimanRutinAll,
        poolPengirimanInstantAll: state.poolPengirimanInstantAll
    }
}
const connectJadwalPengiriman = connect(mapStateToProps, {fetchPoolPengirimanInstant, fetchPoolPengirimanRutinBatch})(JadwalPengiriman);
export {connectJadwalPengiriman}