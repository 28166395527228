const INITIAL_DELIVERY = {
    isLoaded: false,
    data: null
}

export const biayaKirim = (state= INITIAL_DELIVERY, action) => {
    switch(action.type) {
        case "FETCH_BIAYA_KIRIM_RESET":
            return INITIAL_DELIVERY;
        case "FETCH_BIAYA_KIRIM_SUCCESS":
            return {...state, isLoaded: true, data: action.payload};
        case "FETCH_BIAYA_KIRIM_FAILED":
            return {...state, isLoaded: true, data: null};
        default:
            return state;
    }
}
