const INITIAL_POOL_PENGIRIMAN_RUTIN = {
    isLoaded: false,
    pengiriman: null
}
const INITIAL_POOL_PENGIRIMAN_INSTANT = {
    isLoaded: false,
    pengiriman: null
}
export const poolPengirimanRutinAll = (state= INITIAL_POOL_PENGIRIMAN_RUTIN, action) => {
    switch (action.type) {
        case 'FETCH_POOL_PENGIRIMAN_RUTIN_BATCH_RESET':
            return INITIAL_POOL_PENGIRIMAN_RUTIN;
        case 'FETCH_POOL_PENGIRIMAN_RUTIN_BATCH_SUCCESS':
            return {...state, isLoaded: true, pengiriman: action.payload}
        case 'FETCH_POOL_PENGIRIMAN_RUTIN_BATCH_FAILED':
            return {...state, isLoaded: false, pengiriman: null}
        default:
            return state;
    }
}
export const poolPengirimanInstantAll = (state = INITIAL_POOL_PENGIRIMAN_INSTANT, action) => {
    switch(action.type) {
        case 'FETCH_POOL_PENGIRIMAN_INSTANT_RESET':
            return INITIAL_POOL_PENGIRIMAN_INSTANT;
        case 'FETCH_POOL_PENGIRIMAN_INSTANT_SUCCESS':
            return {...state, isLoaded: true, pengiriman: action.payload}
        case 'FETCH_POOL_PENGIRIMAN_INSTANT_FAILED':
            return {...state, isLoaded: false, pengiriman: null}
        default:
            return state;
    }
}