import React from 'react';
import {Modal} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {fetchAlamatMember} from '../../actions/account';

import '../../css/Konfirmasi/konfirmasi.css'

const inlineStyle = {
    modal : {
        position: 'relative',
        height: 'auto',
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  };
class AlamatPengiriman extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            alamat: null,
            office: null,
            distance: -1,
            is_modal_ubah_alamat_open: false
        }
    }
    loadDaftarAlamatUser = async() => {
        await this.props.fetchAlamatMember(window.localStorage.getItem("id_user"));
    }
    countDistance = (alamat_pengiriman_terpilih, office_terpilih) => {
        if (office_terpilih) {
            var latLongOffice = new window.google.maps.LatLng(office_terpilih.latitude_return, office_terpilih.longitude_return);
            var latLongAlamatTerpilih = new window.google.maps.LatLng(alamat_pengiriman_terpilih.latitude_return, alamat_pengiriman_terpilih.longitude_return);
            var distance = window.google.maps.geometry.spherical.computeDistanceBetween(latLongOffice, latLongAlamatTerpilih);
            
            return Math.ceil(distance);
        }
    }
    
    onUbahAlamatButtonClick = (event) => {
        event.preventDefault();
        this.handleModalUbahAlamatOpen();
    }
    onPilihAlamatButtonClick = (id_alamat) => {
        var {memberAlamatAll} = this.props;
        if (memberAlamatAll.isLoaded === true && memberAlamatAll.alamat) {
            var alamatTerpilihObject = memberAlamatAll.alamat.find(element => {
                return element.id_return == id_alamat;
            })
            
            if (this.state.office && alamatTerpilihObject) {
                var distanceBaru = this.countDistance(alamatTerpilihObject, this.state.office);
                this.setState({
                    is_modal_ubah_alamat_open: false,
                    alamat: alamatTerpilihObject,
                    distance: distanceBaru
                })
                this.props.handleUbahAlamatPengiriman(alamatTerpilihObject, distanceBaru);
            }
        }
    }
    handleModalUbahAlamatOpen = () => {
        this.loadDaftarAlamatUser();
        this.setState({
            is_modal_ubah_alamat_open: true
        })
    }
    handleModalUbahAlamatClose = () => {
        this.setState({
            is_modal_ubah_alamat_open: false
        })
    }

    doRenderModalUbahAlamat() {
        var renderedListAlamat = <div></div>;

        var {memberAlamatAll} = this.props;
        if (memberAlamatAll.isLoaded === true && memberAlamatAll.alamat) {
            renderedListAlamat = memberAlamatAll.alamat.map(element => {
                return (
                    <div className="row" key={`addr_${element.id_return}`}>
                        <div className="fourteen wide column">
                            <div>{element.tagline_return}</div>
                            <div>{`${element.alamat_return}`}</div>
                            <div>{`${element.nama_kota_return}, ${element.nama_provinsi_return}`}</div>
                            <div>{`Telepon: ${element.kode_negara_telepon_return}${element.telepon_return}`}</div>
                        </div>
                        <div className="two wide column alamat-pengiriman-section">
                        <div className="alamat-pengiriman-item">
                                <div className="middle-center">
                                <button className="ui black basic button" onClick={() => {this.onPilihAlamatButtonClick(element.id_return)}}>Pilih</button>
                                </div>

                            </div>
                            
                        </div>
                    </div>
                )
            })
        }
        return (
            <Modal open={this.state.is_modal_ubah_alamat_open} 
            onClose={this.handleModalUbahAlamatClose} 
            style={inlineStyle.modal} >
                <Modal.Header>
                    <h3 className="heading">Daftar Alamat</h3>
                </Modal.Header>
                <Modal.Content>
                    <div className="ui stretched grid">
                    {renderedListAlamat}
                    </div>
                    
                </Modal.Content>
            </Modal>
        )
    }
    doRenderAlamatTerpilih() {
        var renderedAlamat;
        var {alamat, isLoaded} = this.props;
        if (isLoaded === false) {
            renderedAlamat = (
                <div>
                    Memuat data...
                </div>
            )
        }
        else {
            if (alamat) {
                renderedAlamat = (
                    <div>
                        <div>{alamat.tagline_return}</div>
                        <div>{`${alamat.alamat_return}, ${alamat.nama_kota_return}, ${alamat.nama_provinsi_return}`}</div>
                        <div>{`Telepon: ${alamat.kode_negara_telepon_return}${alamat.telepon_return}`}</div>
                        <div className="alamat_utilities_button">
                            <button className="ui black basic button" onClick={this.onUbahAlamatButtonClick}>Ubah Alamat</button>
                        </div>
                    </div>
                )
            }
        }
        return renderedAlamat;
    }
    componentDidMount() {
        //console.log("Alamat pengiriman didmount", this.props.alamat)
        //setiap ada perubahan struktur props di parent component, didmount akan diaktifkan lagi
        if (this.props.alamat && this.props.office) {
            this.setState({
                alamat: this.props.alamat,
                office: this.props.office,
                distance: this.countDistance(this.props.alamat, this.props.office)
            })
        }
    }
    componentDidUpdate() {
        // if (this.props.alamat && this.props.office) {
        //     console.log("Alamat Props", this.props.alamat)
        //     if ((this.props.alamat.id_return != this.state.alamat.id_return) ) {
        //         console.log("Jarak berubah", this.countDistance(this.props.alamat, this.props.office))
        //         this.setState({
        //             alamat: this.props.alamat,
        //             office: this.props.office,
        //             distance: this.countDistance(this.props.alamat, this.props.office)
        //         })
        //     }
        //     else {
        //         console.log("Jarak tetap", this.countDistance(this.props.alamat, this.props.office))
        //     }
        // }
        
        // if (this.props.distance && (this.state.distance !== this.props.distance)) {
        //     console.log("Distance berbeda", `${this.state.distance} ${this.props.distance}`)
        //     this.setState({
        //         distance: this.props.distance
        //     })
        // }
    }
    render() {
        return (
            <div>
                <h4 className="heading">Alamat Pengiriman</h4>
                {this.doRenderAlamatTerpilih()}
                {this.doRenderModalUbahAlamat()}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        memberAlamatAll: state.memberAlamatAll
    }
}
const connectAlamatPengiriman = connect(mapStateToProps, {fetchAlamatMember})(AlamatPengiriman);
export {connectAlamatPengiriman};