import React from 'react';
import { connect } from 'react-redux';
import { fetchLinimasaAndDiskonAll } from '../actions/index';

import LinimasaItem from './LinimasaItem';
import history from '../../history';

import '../css/linimasa.css'

class LinimasaList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            linimasa: [],
            diskon: []
        }
    }
    routeChangeWithLinimasa = (id) => {
        let path = `/promo/${id}`;
        history.push(path);
    }
    routeChangeWithKategori = (kat_url_slug, sub_kat_url_slug) => {
        let path = `/kategori/${kat_url_slug}`;
        if (sub_kat_url_slug !== "") path = `${path}/${sub_kat_url_slug}`;
        history.push(path);
    }
    async doLoadDataNeeded() {
        if (this.props.listLinimasa.isLoaded === false) {
            await this.props.fetchLinimasaAndDiskonAll();
            //console.log("Sudah memuat semua linimasa dan diskon")
            this.setState({linimasa: this.props.listLinimasa.linimasa, diskon: this.props.listDiskonAll.diskon});
        }
        else {
            this.setState({linimasa: this.props.listLinimasa.linimasa, diskon: this.props.listDiskonAll.diskon});
        }
    }
    doRenderButtonViewAllWithLinimasa(id_linimasa) {
        return <button
        style={{marginLeft:'auto', marginRight: 'auto', display: 'block', marginTop: '20px'}}
        className="ui black basic button" onClick = {() => {this.routeChangeWithLinimasa(id_linimasa)}}>Lihat Promo</button>
    }
    doRenderButtonViewAllWithKategori(kategori_url_slug, sub_kategori_url_slug) {
        return <button
        style={{marginLeft:'auto', marginRight: 'auto', display: 'block', marginTop: '20px'}}
        className="ui black basic button" onClick = {() => {this.routeChangeWithKategori(kategori_url_slug, sub_kategori_url_slug)}}>Lihat Selengkapnya</button>
    }

    componentDidMount() {
        this.doLoadDataNeeded();
    }
    doRenderLinimasa() {
        //console.log("Rendering...")
        const renderedLinimasaList = this.state.linimasa.map(item => {
            var countItemPreview = 0;
            const renderedPreview = item.preview.map(preview => {
                countItemPreview ++;
                if (countItemPreview <= 5) {
                    var idx = -1;
                    for (var x=0;x<this.state.diskon.length;x++) {
                        if (this.state.diskon[x].id_barang_inventaris_return === preview.id_barang_inventaris_return && 
                            this.state.diskon[x].jenis_pelanggan_return === preview.id_jenis_harga_return) {
                                idx = x;
                                break;
                            }
                    }
                    if (idx >= 0) {
                        return <LinimasaItem key={preview.id_barang_return} item={preview} diskon={this.state.diskon[idx]}/>
                    }
                    else {
                        return <LinimasaItem key={preview.id_barang_return} item={preview} />
                    }
                }
                else {
                    return <div></div>
                }
            });
            var buttonRendered = <div></div>;
            //console.log("Linimasa", item)
            if (item.show_kategori_return === true) {
                buttonRendered = this.doRenderButtonViewAllWithKategori(item.url_slug_kategori_return, item.url_slug_sub_kategori_return)
            }
            else if (item.show_kategori_return === false) {
                buttonRendered = this.doRenderButtonViewAllWithLinimasa(item.id_return);
            }
            return (
                <div key={`linimasa_${item.id_return}`} style={{marginTop: "20px", marginBottom: "80px"}}>
                    <div style={{marginBottom: "10px"}}>
                        <h3 className="heading">{item.title_return}</h3>
                    </div>
                    {/* <div class="ui five doubling link cards">
                        {renderedPreview}
                    </div> */}
                    <div class="ui five column grid">
                        {renderedPreview}
                    </div>
                    <div>
                        {/* <button
                        style={{marginLeft:'auto', marginRight: 'auto', display: 'block', marginTop: '20px'}}
                        class="ui blue  button">Lihat Selengkapnya</button> */}
                        {buttonRendered}
                    </div>
                    
                </div>
                
            ); 
        })

        return renderedLinimasaList;
    }
    render() {
        return (
            <div>
                {this.doRenderLinimasa()}
            </div>
        );
        //return <div>{this.doRenderLinimasa()}</div>
    }
}

const mapStateToProps = (state) => {
    //state disini diambil dari reducers yang ada di ./reducers/index.
    //di index ada object dengan key 'listpengumuman', maka ini pun ada
    return { listLinimasa: state.listLinimasa, listDiskonAll:  state.listDiskonAll}
}

export default connect(mapStateToProps, { fetchLinimasaAndDiskonAll })(LinimasaList);