import React from 'react';
import { Form } from 'semantic-ui-react';

import '../../css/ProductDetail/quantity-to-troli.css'
class QuantityToTroli extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            jumlah: 0,
            jumlahDiTroli: 0,
            errorMessage: ""
        }
    }
    onJumlahInputChange = (event) => {
        event.preventDefault();
        if (this.state.errorMessage !== "") {
            this.setState({
                errorMessage: ""
            })
        }
        
        if (event.target.value !== "") {
            var inputInt = parseInt(event.target.value);
            if (!Number.isNaN(inputInt)) {
                if (inputInt > 0) {
                    this.setState({
                        jumlah: inputInt
                    })
                }
                else {
                    this.setState({
                        jumlah: (0 - inputInt)
                    })
                }
            }
            else {
                this.setState({
                    jumlah: 0
                })
            }
        }
        else {
            this.setState({
                jumlah: 0
            })
        }
    }
    onMinusButtonClick = event => {
        event.preventDefault();
        var jumlahBaru = this.state.jumlah - 1;
        if (jumlahBaru >= 0) {
            this.setState({
                jumlah: jumlahBaru
            })
        }
    }
    onPlusButtonClick = event => {
        event.preventDefault();
        var jumlahBaru = this.state.jumlah + 1;
        if (jumlahBaru >= 0) {
            this.setState({
                jumlah: jumlahBaru
            })
        }
    }
    onTambahKeTroliButtonClick = (event) => {
        event.preventDefault();
        var troliArray = [];
        if (window.localStorage.getItem("troli")) {
            troliArray = JSON.parse(window.localStorage.getItem("troli"));
        }

        //1. build item object
        if (this.state.jumlah > 0) {
            
            var troliObject = troliArray.find(element => element.id_barang_dijual === this.props.product.id_barang_return );
            if (troliObject) {
                troliObject.jumlah_order = this.state.jumlah
            }
            else {
                troliObject = {
                    "id_barang_dijual": this.props.product.id_barang_return,
                    "id_barang_inventaris": this.props.product.id_barang_inventaris_return,
                    "jumlah_order": this.state.jumlah
                }
                troliArray.push(troliObject);
            }

            window.localStorage.setItem("troli", JSON.stringify(troliArray));

            this.props.handleModalMasukTroliOpen();
        }
        else {
            this.setState({
                errorMessage: "Masukkan jumlah barang."
            })
        }
    }
    doRenderWarning() {
        return (
            <div>{this.state.errorMessage}</div>
        );
    }
    doRenderElement() {
        return (
            <div className="quantity-to-troli">
                <div className="ui stretched grid">
                    <div className="four wide column">
                        <label>Jumlah</label>
                            
                        <div class="ui inline input">
                            <div className="left-center" onClick={this.onMinusButtonClick}><img style={{width: "24px", height: "24px"}} src="/assets/drawables/minus.svg"></img></div>
                            <input type="text" className="input-width" style={{textAlign: "center"}} value={this.state.jumlah} onChange={this.onJumlahInputChange} ></input>
                            <div className="right-center" onClick={this.onPlusButtonClick}><img style={{width: "24px", height: "24px"}} src="/assets/drawables/plus.svg"></img></div>
                        </div>
                        
                        
                    </div>
                    
                    <div className="column eq-section">
                        <div className="eq-item">
                            <div className="bottom">
                            <button className="ui button button-troli"  onClick={this.onTambahKeTroliButtonClick}>Masukkan ke Troli</button>
                            </div>
                        </div>
                    </div>
                </div>
                {this.doRenderWarning()}
            </div>
            
        )
    }
    componentDidMount() {
        var troliArray = [];
        if (window.localStorage.getItem("troli")) {
            troliArray = JSON.parse(window.localStorage.getItem("troli"));
        }
        var troliObject = troliArray.find(element => element.id_barang_dijual === this.props.product.id_barang_return );
        if (troliObject) {
            this.setState({
                jumlah: troliObject.jumlah_order
            })
        }
    }
    render() {
        console.log(window.localStorage.getItem("troli"))
        return (
            <div>
                {this.doRenderElement()}
            </div>
        )
    }
}

export default QuantityToTroli;