const INITIAL_STATE_INFO = {
    isLoaded: false,
    product: null
};
const INITIAL_DRAFT_STATE_INFO = {
    isLoaded: false,
    barang_dijual: null,
    diskon: null
}

export const productInfo = (state = INITIAL_STATE_INFO, action) => {
    switch(action.type) {
        case 'FETCH_PRODUCT_INFO':
            return {...state, isLoaded: true, product: action.payload};
        case 'FETCH_PRODUCT_INFO_RESET':
            return INITIAL_STATE_INFO;
        default:
            return state;
    }
}

export const productDraftBatch = (state = INITIAL_DRAFT_STATE_INFO, action) => {
    switch(action.type) {
        case 'FETCH_BARANG_DRAFT_RESET':
            return INITIAL_DRAFT_STATE_INFO;
        case 'FETCH_BARANG_DRAFT_SUCCESS':
            return {...state, isLoaded: true, barang_dijual: action.barang_dijual_payload, diskon: action.diskon_payload};
        case 'FETCH_BARANG_DRAFT_FAILED':
            return {...state, isLoaded: true, barang_dijual: null, diskon: null}
        default:
            return state;
    }
}