import React from 'react';
import {Helmet} from 'react-helmet';
import RegularBar from '../universal/RegularBar';
import {Modal, Button} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {fetchAlamatMember, fetchUserZennyAndPoin} from '../actions/account';
import {fetchBarangDijualDariDraft} from '../actions/product';
import {fetchOfficeDetail} from '../actions/warehouse';
import {fetchBiayaKirim} from '../actions/delivery';
import {fetchPromoCashbackInfo, fetchPromoDeliveryInfo} from '../actions/promotion';
import {fetchInsertTransaksiBaru} from '../actions/transaksi';

import {connectAlamatPengiriman as AlamatPengiriman} from '../page_components/Konfirmasi/AlamatPengiriman';
import DetailPesanan from '../page_components/Konfirmasi/DetailPesanan';
import CatatanTambahan from '../page_components/Konfirmasi/CatatanTambahan';
import MetodePembayaran from '../page_components/Konfirmasi/MetodePembayaran'
import DetailPembayaran from '../page_components/Konfirmasi/DetailPembayaran';
import {connectJadwalPengiriman as JadwalPengiriman} from '../page_components/Konfirmasi/JadwalPengiriman';
import '../css/Konfirmasi/konfirmasi.css';
import Cookies from 'universal-cookie';

const inlineStyle = {
    modal : {
        position: 'relative',
        height: 'auto',
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  };
class Konfirmasi extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            alamat_pengiriman_terpilih: null,
            distance: -1,
            day_pengiriman_terpilih: null,
            date_pengiriman_terpilih: null,
            pool_pengiriman_terpilih: null,
            catatan_tambahan: "",

            //detail pesanan
            barang_pesanan: null,
            metode_pembayaran_terpilih: null,
            promo_cashback: null,
            promo_biaya_kirim: null,
            total_zenny: 0,
            total_poin: 0,
            total_harga_barang_sebelum_diskon: 0,
            total_harga_barang_setelah_diskon: 0,
            total_biaya_kirim_reguler: 0,
            total_biaya_kirim_instant: 0,

            //modal konfirmasi
            is_modal_konfirmasi_pesanan_error_open: false,
            modal_konfirmasi_pesanan_error_message: null
        }
        this.total_biaya_kirim_terpilih = 0;
        this.cookies = new Cookies();
    }
    doRenderHeader() {
        var titleStr = "Konfirmasi Pesanan - Henata"
        return (
            <Helmet>
                <title>{titleStr}</title>
            </Helmet>
        )
    }
    handleModalErrorKonfirmasiPesananOpen = (message) => {
        this.setState({
            is_modal_konfirmasi_pesanan_error_open: true,
            modal_konfirmasi_pesanan_error_message: message
        })
    }
    handleModalErrorKonfirmasiPesananClose = () => {
        this.setState({
            is_modal_konfirmasi_pesanan_error_open: false,
            modal_konfirmasi_pesanan_error_message: null
        })
    }
    validateTransaksi = () => {
        var returnObject = {}
        var {pool_pengiriman_terpilih, distance, date_pengiriman_terpilih, alamat_pengiriman_terpilih, barang_pesanan} = this.state;
        if (!pool_pengiriman_terpilih) {
            returnObject.status = false;
            returnObject.errorMessage = "Jadwal pengiriman belum dipilih."
            return returnObject;
        }
        if (distance === -1) {
            returnObject.status = false;
            returnObject.errorMessage = "Jarak belum selesai dihitung."
            return returnObject;
        }
        if (!date_pengiriman_terpilih) {
            returnObject.status = false;
            returnObject.errorMessage = "Jarak belum selesai dihitung."
            return returnObject;
        }
        if (!alamat_pengiriman_terpilih) {
            returnObject.status = false;
            returnObject.errorMessage = "Alamat pengiriman belum ada."
            return returnObject;
        }
        if (!barang_pesanan) {
            returnObject.status = false;
            returnObject.errorMessage = "Barang pesanan belum ada."
            return returnObject;
        }

        returnObject.status = true;
        returnObject.errorMessage = null;

        return returnObject;
    }
    validatePromoBiayaKirim = () => {
        var {promo_biaya_kirim, pool_pengiriman_terpilih, total_harga_barang_setelah_diskon} = this.state;
        if (promo_biaya_kirim && pool_pengiriman_terpilih) {
            if (pool_pengiriman_terpilih.nama_tipe_pool_return === "INSTANT_POOL") {
                if (promo_biaya_kirim.potongan_atas_return === "BIAYA_KIRIM_INSTANT") {
                    if (total_harga_barang_setelah_diskon >= promo_biaya_kirim.minimum_pembelian_return && this.total_biaya_kirim_terpilih > 0) {
                        return true;
                    }
                    else return false;
                }
                else {
                    return false;
                }
            }
            else {
                if (promo_biaya_kirim.potongan_atas_return === "BIAYA_KIRIM") {
                    if (total_harga_barang_setelah_diskon >= promo_biaya_kirim.minimum_pembelian_return && this.total_biaya_kirim_terpilih > 0) {
                        return true;
                    }
                    else return false;
                }
                else {
                    return false;
                }
            }
        }
    }
    wrapTransaksiBarangPesanan = () => {
        var barangArray = [];
        var {barang_pesanan} = this.state;
        barangArray = barang_pesanan.map(element => {
            var object = {};
            object.id_barang_snapshot = element.id_barang_snapshot_return;
            object.id_barang_inventaris = element.id_barang_inventaris_return;
            object.jumlah_pembelian = element.troli.jumlah_order;
            object.id_jenis_harga = element.id_jenis_harga_return.toString();
            object.harga_barang = element.harga_barang_return;
            object.harga_pokok = element.harga_pokok_return;
            object.id_kategori = element.id_kategori_return;
            object.id_sub_kategori = element.id_sub_kategori_return;

            if (element.diskon) {
                var diskonArray = [];
                var diskonObject = {};
                diskonObject.id = element.diskon.id_return;
                diskonObject.id_barang_inventaris = element.diskon.id_barang_inventaris_return;
                diskonObject.jenis_diskon = element.diskon.jenis_diskon_return;
                diskonObject.diskon_persen = element.diskon.diskon_persen_return;
                diskonObject.diskon_value = element.diskon.diskon_value_return;
                diskonObject.min_pembelian = element.diskon.min_pembelian_return;
                diskonObject.max_pembelian = element.diskon.max_pembelian_return;
                diskonObject.jenis_pelanggan = element.diskon.jenis_pelanggan_return.toString();

                diskonArray.push(diskonObject);

                object.is_diskon_applied = true;
                object.diskon = diskonArray;
            }
            else {
                object.is_diskon_applied = false;
                object.diskon = [];
            }

            return object;
        });

        return barangArray;
    }
    wrapTransaksiInfo = () => {
        var object = {};

        if (this.state.metode_pembayaran_terpilih === "zenny"){
            object.pay_sercash = true;
        }
        else {
            object.pay_sercash = false;
        }

        object.id_pelanggan = this.cookies.get("user").id_user_return;
        object.deskripsi = this.state.catatan_tambahan;
        object.alamat_tujuan = this.state.alamat_pengiriman_terpilih.alamat_return;
        object.telepon_tujuan = this.state.alamat_pengiriman_terpilih.telepon_return;
        object.kode_negara_telepon_tujuan = this.state.alamat_pengiriman_terpilih.kode_negara_telepon_return;
        object.deskripsi_tujuan = this.state.alamat_pengiriman_terpilih.deskripsi_alamat_return;
        object.id_pool_pengiriman = this.state.pool_pengiriman_terpilih.id_return;
        object.nama_tipe_pool_pengiriman = this.state.pool_pengiriman_terpilih.nama_tipe_pool_return;
        object.tanggal_pengiriman = this.state.date_pengiriman_terpilih;
        object.distance = this.state.distance;
        object.latitude_tujuan = this.state.alamat_pengiriman_terpilih.latitude_return;
        object.longitude_tujuan = this.state.alamat_pengiriman_terpilih.longitude_return;
        object.platform = "DESKTOP_WEB";
        if (this.validatePromoBiayaKirim() === true) {
            object.id_promotion = this.state.promo_biaya_kirim.id_return;
        }
        object.barang = this.wrapTransaksiBarangPesanan();

        return object;
    }
    handleKonfirmasiPesananButtonClick = () => {
        var validator = this.validateTransaksi();
        if (validator.status === true) {
            //console.log(this.wrapTransaksiInfo())
            this.insertTransaksiBaruToServer(this.wrapTransaksiInfo())
        }
        else {
            this.handleModalErrorKonfirmasiPesananOpen(validator.errorMessage);
        }
    }

    insertTransaksiBaruToServer = async(object) => {
        await this.props.fetchInsertTransaksiBaru(object);
        
        var {insertTransaksiBaru} = this.props;
        if (insertTransaksiBaru.isLoaded === true && insertTransaksiBaru.total_harga_barang !== null) {
            window.localStorage.removeItem("troli");
            window.location.replace("/");
        }
    }
    
    loadAllDataNeeded = async () => {
        //console.log("1. load alamat warehouse")
        await this.loadAlamatWarehouse();
        // console.log("1. load alamat warehouse selesai")
        // console.log("2. load alamat default")
        await this.loadAlamatDefault();
        // console.log("2. load alamat default selesai")
        // console.log("3. load draft")
        await this.loadDraftBatch();
        // console.log("3. load draft selesai")
        // console.log("4. load zenny")
        await this.loadZennyPoin();
        // console.log("4. load zenny")
        // console.log("5. load biaya kirim")
        await this.loadBiayaKirim();
        // console.log("5. load biaya kirim selesai")
    }
    loadAlamatDefault = async () => {
        await this.props.fetchAlamatMember(window.localStorage.getItem("id_user"));

        var {memberAlamatAll} = this.props;
        if (memberAlamatAll.isLoaded === true && memberAlamatAll.alamat) {
            this.setState({
                alamat_pengiriman_terpilih: memberAlamatAll.alamat[0],
                distance: this.countDistance(memberAlamatAll.alamat[0])
            })
        }
    }
    loadAlamatWarehouse = async () => {
        await this.props.fetchOfficeDetail();
    }
    loadDraftBatch = async () => {
        if (window.localStorage.getItem("troli") && this.cookies.get("user")) {
            var troliArray = JSON.parse(window.localStorage.getItem("troli"));
            if (troliArray.length > 0) {
                var arrayInventaris = troliArray.map(element => element.id_barang_inventaris);
                var bodyObject = {
                    "array_inventaris": arrayInventaris,
                    "id_jenis_harga": this.cookies.get("user").jenis_return.toString()
                }
                //console.log(bodyObject)
                await this.props.fetchBarangDijualDariDraft(bodyObject);
                
                var {barang_dijual, diskon} = this.props.productDraftBatch;
                var barangPesananArray = barang_dijual.map(element => {
                    var troliObject = troliArray.find(troliElement => {
                        return troliElement.id_barang_dijual === element.id_barang_return
                    });
                    var diskonObject = diskon.find(diskonElement => {
                        return diskonElement.id_barang_inventaris_return === element.id_barang_inventaris_return &&
                        diskonElement.jenis_pelanggan_return === element.id_jenis_harga_return
                    });

                    if (troliObject) {
                        element.troli = troliObject;
                    }
                    if (diskonObject) {
                        element.diskon = diskonObject;
                    }

                    return element;
                });

                this.setState({
                    barang_pesanan: barangPesananArray
                })
            }
        }
    }
    loadZennyPoin = async () => {
        await this.props.fetchUserZennyAndPoin();
        var {memberZennyPoin} = this.props;
        if (memberZennyPoin.isLoaded === true && memberZennyPoin.result !== null) {
            this.setState({
                total_zenny: memberZennyPoin.result.sercash_member,
                total_poin: memberZennyPoin.result.poin_member
            })
        }
    }
    loadBiayaKirim = async () => {
        if (this.state.distance !== -1 && this.state.total_harga_barang_setelah_diskon !== 0) {
            await this.props.fetchBiayaKirim(this.state.total_harga_barang_setelah_diskon, this.state.distance);
            
            var {biayaKirim} = this.props;
            if (biayaKirim.isLoaded === true && biayaKirim.data !== null) {
                this.setState({
                    total_biaya_kirim_reguler: biayaKirim.data.delivery_price_return,
                    total_biaya_kirim_instant: biayaKirim.data.instant_delivery_price_return
                })
            }
            await this.loadPromoBiayaKirimInfo("RUTIN_POOL");
        }
    }
    loadPromoCashbackInfo = async () => {
        await this.props.fetchPromoCashbackInfo();
    }
    loadPromoBiayaKirimInfo = async (nama_tipe_pool) => {
        await this.props.fetchPromoDeliveryInfo(nama_tipe_pool);
        var {promoDeliveryInfo} = this.props;

        this.setState({
            promo_biaya_kirim: null
        })
        if (promoDeliveryInfo.isLoaded === true && promoDeliveryInfo.result !== null) {
            if (promoDeliveryInfo.result.length > 0) {
                this.setState({
                    promo_biaya_kirim: promoDeliveryInfo.result[0]
                })
            }
        }
    }
    
    loadBiayaKirimWithParams = async (distance_baru) => {
        if (this.state.total_harga_barang_setelah_diskon !== 0) {
            await this.props.fetchBiayaKirim(this.state.total_harga_barang_setelah_diskon, distance_baru);
            
            var {biayaKirim} = this.props;
            if (biayaKirim.isLoaded === true && biayaKirim.data !== null) {
                this.setState({
                    total_biaya_kirim_reguler: biayaKirim.data.delivery_price_return,
                    total_biaya_kirim_instant: biayaKirim.data.instant_delivery_price_return
                })
            }
        }
    }
    //handle pilih pool pengiriman
    handlePoolPengiriman = async (day_pengiriman_terpilih, date_pengiriman_terpilih, pool_pengiriman_terpilih) => {
        this.setState({
            day_pengiriman_terpilih: day_pengiriman_terpilih,
            date_pengiriman_terpilih: date_pengiriman_terpilih,
            pool_pengiriman_terpilih: pool_pengiriman_terpilih
        });

        await this.loadPromoBiayaKirimInfo(pool_pengiriman_terpilih.nama_tipe_pool_return)
    }
    handleCatatanTambahan = (catatan_tambahan) => {
        this.setState({
            catatan_tambahan: catatan_tambahan
        })
    }
    countDistance = (alamat_pengiriman_terpilih) => {
        var {warehouseOfficeDetail} = this.props;
        if (warehouseOfficeDetail.isLoaded === true && alamat_pengiriman_terpilih !== null) {
            var latLongOffice = new window.google.maps.LatLng(warehouseOfficeDetail.data.latitude_return, warehouseOfficeDetail.data.longitude_return);
            var latLongAlamatTerpilih = new window.google.maps.LatLng(alamat_pengiriman_terpilih.latitude_return, alamat_pengiriman_terpilih.longitude_return);
            var distance = window.google.maps.geometry.spherical.computeDistanceBetween(latLongOffice, latLongAlamatTerpilih);
            
            return Math.ceil(distance);
            //console.log("Jarak : ", distance);
        }
    }
    handleUbahAlamatPengiriman = async (alamat_pengiriman_terpilih, distance_alamat_terpilih) => {
        //console.log("Jarak baru", this.countDistance(alamat_pengiriman_terpilih));
        await this.loadBiayaKirimWithParams(distance_alamat_terpilih);

        this.setState({
            alamat_pengiriman_terpilih: alamat_pengiriman_terpilih,
            distance: distance_alamat_terpilih
        })
    }
    handlePilihMetodePembayaran = (metode_pembayaran) => {
        this.setState({
            metode_pembayaran_terpilih: metode_pembayaran
        })
    }
    handleDetailPesanan = (total_harga_barang_sebelum_diskon, total_diskon, total_harga_barang_setelah_diskon) => {
        this.setState({
            total_harga_barang_sebelum_diskon,
            total_diskon,
            total_harga_barang_setelah_diskon
        })
    }
    
    //render tiap komponen
    doRenderModalErrorKonfirmasi() {
        return (
            <Modal
            size="tiny"
            style={inlineStyle.modal}
            onClose={this.handleModalErrorKonfirmasiPesananClose}
            open={this.state.is_modal_konfirmasi_pesanan_error_open} >
                <Modal.Header>
                    Ups!
                </Modal.Header>
                <Modal.Content>
                    <p>{this.state.modal_konfirmasi_pesanan_error_message}</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={this.handleModalErrorKonfirmasiPesananClose}>Okay</Button>
                </Modal.Actions>
            </Modal>
        )
    }
    doRenderAlamatPengiriman() {
        var renderedAlamat;
        var {memberAlamatAll} = this.props;
        if (memberAlamatAll.isLoaded === false) {
            renderedAlamat = (
                <div>
                    <AlamatPengiriman isLoaded={false} />
                </div>
            )
        }
        else {
            if (this.state.alamat_pengiriman_terpilih && 
                this.props.warehouseOfficeDetail.isLoaded === true &&
                this.props.warehouseOfficeDetail.data !== null) {
                renderedAlamat = (
                    <div>
                        <AlamatPengiriman office={this.props.warehouseOfficeDetail.data} handleUbahAlamatPengiriman={this.handleUbahAlamatPengiriman} alamat={this.state.alamat_pengiriman_terpilih} isLoaded={true} />
                    </div>
                )
            }
        }
        return renderedAlamat;
    }
    doRenderDetailPesanan() {
        var {barang_pesanan} = this.state;
        if (barang_pesanan) {
            return (
                <div>
                    <DetailPesanan handleDetailPesanan={this.handleDetailPesanan} pesanan={barang_pesanan} />
                </div>
            )
        }
        else {

        }
    }
    doRenderPengiriman() {
        return (
            <div>
                <JadwalPengiriman handlePoolPengiriman={this.handlePoolPengiriman} />
            </div>
        )
    }
    doRenderCatatanTambahan() {
        return (
            <div>
                <CatatanTambahan />
            </div>
        )
    }
    doRenderMetodePembayaran() {
        var {memberZennyPoin} = this.props;
        if (memberZennyPoin.isLoaded === true && memberZennyPoin.result !== null) {
            return <MetodePembayaran handlePilihMetodePembayaran={this.handlePilihMetodePembayaran} isLoaded={true} zenny={memberZennyPoin.result.sercash_member} poin={memberZennyPoin.result.poin_member} />
        }
        else if (memberZennyPoin.isLoaded === true && memberZennyPoin.result == null) {
            return <MetodePembayaran isLoaded={true} zenny={null} poin={null} />
        }
        else {
            return <MetodePembayaran isLoaded={false} />
        }
    }
    doRenderDetailPembayaran() {
        var {
            pool_pengiriman_terpilih,
            total_harga_barang_setelah_diskon, 
            total_biaya_kirim_instant, 
            total_biaya_kirim_reguler,
            metode_pembayaran_terpilih,
            promo_biaya_kirim,
            total_zenny,
            total_poin
        } = this.state;

        // console.log("Biaya kirim", total_biaya_kirim_reguler)

        if (total_harga_barang_setelah_diskon !== 0) {
            var biaya_kirim_terpilih = -1;
            var promo_biaya_kirim_terpilih = 0;
            if (pool_pengiriman_terpilih) {
                if (pool_pengiriman_terpilih.nama_tipe_pool_return === "INSTANT_POOL") {
                    biaya_kirim_terpilih = total_biaya_kirim_instant;
                }
                else biaya_kirim_terpilih = total_biaya_kirim_reguler
            }
            else biaya_kirim_terpilih = total_biaya_kirim_reguler;
            this.total_biaya_kirim_terpilih = biaya_kirim_terpilih;

            if (promo_biaya_kirim) {
                if (pool_pengiriman_terpilih && pool_pengiriman_terpilih.nama_tipe_pool_return === "INSTANT_POOL") {
                    if (promo_biaya_kirim.potongan_atas_return === "BIAYA_KIRIM_INSTANT") {
                        if (total_harga_barang_setelah_diskon >= promo_biaya_kirim.minimum_pembelian_return) {
                            if (biaya_kirim_terpilih >= promo_biaya_kirim.jumlah_potongan_return) {
                                promo_biaya_kirim_terpilih = promo_biaya_kirim.jumlah_potongan_return;
                            }
                            else {
                                promo_biaya_kirim_terpilih = biaya_kirim_terpilih;
                            }
                        }
                        else promo_biaya_kirim_terpilih = 0;
                    }
                }
                else {
                    if (promo_biaya_kirim.potongan_atas_return === "BIAYA_KIRIM") {
                        if (total_harga_barang_setelah_diskon >= promo_biaya_kirim.minimum_pembelian_return) {
                            if (biaya_kirim_terpilih >= promo_biaya_kirim.jumlah_potongan_return) {
                                promo_biaya_kirim_terpilih = promo_biaya_kirim.jumlah_potongan_return;
                            }
                            else {
                                promo_biaya_kirim_terpilih = biaya_kirim_terpilih;
                            }
                        }
                        else promo_biaya_kirim_terpilih = 0;
                    }
                }
            }

            var total_harga_barang_final = total_harga_barang_setelah_diskon + biaya_kirim_terpilih - promo_biaya_kirim_terpilih;

            var zenny_terpakai = 0;
            if (total_zenny >= 1000) {
                if (total_harga_barang_final >= total_zenny) {
                    var zenny_terpakai_sblm_floor = total_zenny;

                    zenny_terpakai = Math.floor(zenny_terpakai_sblm_floor / 1000) * 1000;
                }
                else {
                    zenny_terpakai = total_harga_barang_final;
                }
            }
            else {
                zenny_terpakai = 0;
            }
            //console.log("Promo biaya kirim terpilih", promo_biaya_kirim_terpilih)

            return <DetailPembayaran
            handleKonfirmasiPesananButtonClick={this.handleKonfirmasiPesananButtonClick}
            total_harga_barang_setelah_diskon={total_harga_barang_setelah_diskon}
            total_biaya_kirim={biaya_kirim_terpilih} promo_biaya_kirim={promo_biaya_kirim_terpilih} 
            metode_pembayaran={metode_pembayaran_terpilih} zenny_terpakai={zenny_terpakai}
             />
        }
        else {
            return <div></div>
        }
    }

    doRenderPage() {
        var renderPage = (
            <div  className="ui main container">
                <h3 className="heading">Konfirmasi</h3>
                <div className="ui grid">
                    <div className="eleven wide column">
                        {this.doRenderAlamatPengiriman()}
                        <div className="ui grid" style={{marginTop: "1em"}}>
                            <div className="ten wide column">
                                {this.doRenderDetailPesanan()}
                            </div>
                            <div className="six wide column">
                                {this.doRenderPengiriman()}
                                {this.doRenderCatatanTambahan()}
                            </div>
                        </div>
                    </div>
                    <div className="five wide column">
                        <div className="ui segment">
                        {this.doRenderMetodePembayaran()}
                        {this.doRenderDetailPembayaran()}
                        </div>
                        
                    </div>
                </div>
            </div>
            
        );
        return renderPage;
    }
    componentDidMount() {
        this.loadAllDataNeeded();
    }
    componentDidUpdate() {

        // var {barang_pesanan, distance, metode_pembayaran_terpilih, trigger_total_biaya_kirim} = this.state;
        // if (barang_pesanan && distance !== -1 && metode_pembayaran_terpilih) {
        //     console.log("Sudah load semua, waktunya ")
        // }
    }
    render() {
        return (
            <div>
                {this.doRenderHeader()}
                <RegularBar />
                {this.doRenderPage()}
                {this.doRenderModalErrorKonfirmasi()}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        memberAlamatAll: state.memberAlamatAll,
        memberZennyPoin: state.memberZennyPoin,
        productDraftBatch: state.productDraftBatch,
        warehouseOfficeDetail: state.warehouseOfficeDetail,
        biayaKirim: state.biayaKirim,
        promoDeliveryInfo: state.promoDeliveryInfo,
        promoCashbackInfo: state.promoCashbackInfo,
        insertTransaksiBaru: state.insertTransaksiBaru
    }
}
const connectKonfirmasi = connect(mapStateToProps, 
    {
        fetchAlamatMember, 
        fetchUserZennyAndPoin, 
        fetchBarangDijualDariDraft, 
        fetchOfficeDetail,
        fetchBiayaKirim,
        fetchPromoDeliveryInfo,
        fetchPromoCashbackInfo,
        fetchInsertTransaksiBaru
    })(Konfirmasi);
export {connectKonfirmasi}