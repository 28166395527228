import React from 'react';
import {Helmet} from 'react-helmet';
import {DESKTOP_SITE, MOBILE_SITE, TWITTER_SITE, OG_DEFAULT_IMAGE} from '../actions/types';

import PengumumanList from '../linimasa/PengumumanList';
import FeaturedKategoriList from '../kategori/FeaturedKategoriList';
import LinimasaList from '../linimasa/LinimasaList';
import { connectSEOHome as SEOHome } from '../universal/SEOHome';
import { connectSearchPanel as SearchBar} from '../universal/SearchBar';

class HomePage extends React.Component {
    doRenderHeader() {
        var titleStr = "Situs Tempat Belanja Kebutuhanmu! - Henata";
        var descriptionStr = "Henata, situs belanja kebutuhan yang mudah, hemat, terpercaya. Hemat waktu, banyak diskon, bisa cash on delivery."
        return (
            <Helmet>
                <title>{titleStr}</title>
                <link rel="alternate" media="only screen and (max-width: 640px)" href={MOBILE_SITE + this.props.location.pathname} />
                <meta name="description" content={descriptionStr} />

                <meta property="og:title" content={titleStr} />
                <meta property="og:type" content="website" />
                <meta property="og:description" content={descriptionStr} />
                <meta property="og:image" content={OG_DEFAULT_IMAGE} />
                <meta property="og:url" content={DESKTOP_SITE + this.props.location.pathname} />

                <meta name="twitter:card" content="summary" />
                <meta name="twitter:site" content={TWITTER_SITE} />
            </Helmet>
        );
    }
    render() {
        return (
            <div>
                {this.doRenderHeader()}
                <SearchBar />
                <div className="ui main container">
                    <div class="ui two column stretched grid padded">
                        <div className="column">
                            <PengumumanList />
                        </div>
                        <div className="column">
                            <FeaturedKategoriList />
                        </div>
                    </div>
                    <div class="ui grid">
                        <LinimasaList />
                    </div>
                    <div>
                        <SEOHome />
                    </div>
                </div>
            </div>
        );
    }
}

export default HomePage;