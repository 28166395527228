import React from 'react';
import { connect } from 'react-redux';
import { fetchKategoriAndSubKategori } from '../actions/kategori';

import SubKategoriList from './SubKategoriList';

class KategoriList extends React.Component {
    componentDidMount() {
        window.scrollTo(0,0 );
        if (this.props.listKategoriAll.isLoaded === false) {
            this.props.fetchKategoriAndSubKategori();
            console.log("Loading..")
        } 
    }
    doRenderKategori() {
        const { listKategoriAll, listSubKategori } = this.props;
        const renderedKategoriList = listKategoriAll.kategori.map(kategori => {
            var idx = -1;
            for (var x=0;x<listSubKategori.length;x++) {
                if (listSubKategori[x].id_kategori === kategori.id_return.toString()) {
                    idx = x;
                    break;
                }
            }
            if (idx >= 0) {
                return (
                    <div key={`kategori_${kategori.id_return}`} style={{marginTop: "20px", marginBottom: "80px"}}>
                        <div style={{marginBottom: "10px"}}>
                            <h3>{kategori.nama_return}</h3>
                        </div>
                        <div style={{marginTop: "20px"}}>
                            <SubKategoriList list={listSubKategori[idx].sub_kategori} kategori={kategori} />
                        </div>
                    </div>
                );
            }
            else {
                return <div></div>
            }
        });
        return renderedKategoriList;
    }
    render() {
        return(
            <div>
                {this.doRenderKategori()}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return { listKategoriAll: state.listKategoriAll, listSubKategori: state.listSubKategori }
};

export default connect(mapStateToProps, { fetchKategoriAndSubKategori })(KategoriList);