import { combineReducers } from 'redux';
import { listPengumuman, pengumumanInfo } from './pengumuman/pengumumanReducers';
import { listLinimasa, linimasaInfo, listProductByLinimasaId } from './linimasa/linimasaReducers';
import { listDiskonAll, diskonByInventaris, diskonByInventarisBatch } from './diskon/diskonReducers';
import { listKategoriAll, listSubKategoriByIdKategori, kategoriByUrlSlug, subkategoriByUrlSlug } from './kategori/kategoriReducers';
import { productInfo, productDraftBatch } from './product/productReducers';
import { privacyPolicy, termsCondition, seoHome } from './statics/staticReducers';

//account reducers
import { accountLogin, accountRegister, accountValidation, memberAlamatAll, memberZennyPoin } from './account/accountReducers';
import { poolPengirimanInstantAll, poolPengirimanRutinAll } from './pool_pengiriman/poolPengirimanReducers'
import { warehouseOfficeDetail } from './warehouse/warehouseReducers';
import { biayaKirim } from './delivery/deliveryReducers';
import { promoCashbackInfo, promoDeliveryInfo } from './promotion/promotionReducers';
import { insertTransaksiBaru } from './transaksi/transaksiReducers';

//search
import { searchProductByKategori, searchProductByKeyword, searchKeywordSuggestion } from './search/searchReducers';

export default combineReducers({
    listPengumuman : listPengumuman,
    listLinimasa : listLinimasa,
    listDiskonAll : listDiskonAll,
    listKategoriAll : listKategoriAll,
    listSubKategori : listSubKategoriByIdKategori,
    productInfo : productInfo,
    linimasaInfo: linimasaInfo,
    listProductByLinimasaId: listProductByLinimasaId,
    pengumumanInfo: pengumumanInfo,
    diskonByInventaris: diskonByInventaris,
    diskonByInventarisBatch: diskonByInventarisBatch,
    kategoriUrlSlug: kategoriByUrlSlug,
    subkategoriUrlSlug: subkategoriByUrlSlug,
    searchProductByKategori: searchProductByKategori,
    searchProductByKeyword: searchProductByKeyword,
    searchKeywordSuggestion: searchKeywordSuggestion,
    privacyPolicy: privacyPolicy,
    termsCondition: termsCondition,
    seoHome: seoHome,

    //product draft di troli
    productDraftBatch: productDraftBatch,

    //account
    accountLogin: accountLogin,
    accountRegister: accountRegister,
    accountValidation: accountValidation,
    //pool pengiriman
    poolPengirimanInstantAll: poolPengirimanInstantAll,
    poolPengirimanRutinAll: poolPengirimanRutinAll,
    //warehouse
    warehouseOfficeDetail: warehouseOfficeDetail,
    //biaya kirim
    biayaKirim: biayaKirim,
    //promotion
    promoCashbackInfo: promoCashbackInfo,
    promoDeliveryInfo: promoDeliveryInfo,
    //transaksi
    insertTransaksiBaru: insertTransaksiBaru,

    //alamat
    memberAlamatAll: memberAlamatAll,
    //zenny
    memberZennyPoin: memberZennyPoin
})
