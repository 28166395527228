import mapis from '../../api/mapis'
import { JENIS_REGULER } from './types';


export const fetchDiskonAll = () => async dispatch => {
    dispatch({
        type: 'FETCH_RESET_DISKON_ALL'
    })
    const response = await mapis.get('/mart/diskon/all');

    if (response.data.status==="success") {
        dispatch({
            type: 'FETCH_DISKON_ALL',
            payload: response.data.data
        })
    }
} 

export const fetchDiskonByInventaris = (id_barang_inventaris) => async dispatch => {
    dispatch({
        type: 'FETCH_RESET_DISKON_BY_INVENTARIS'
    })
    
    const response = await mapis.get('/mart/diskon/inventaris', {
        params: {
            id: id_barang_inventaris
        }
    });
    
    if (response.data.status === "success") {
        var idx = -1;
        for (var x=0;x<response.data.data.length;x++) {
            if (response.data.data[x].jenis_pelanggan_return === JENIS_REGULER) {
                idx = x;
                break;
            }
        }
        if (idx >= 0) {
            dispatch({
                type: 'FETCH_DISKON_INVENTARIS_EXIST',
                payload: response.data.data[idx]
            })
        }
        else {
            dispatch({
                type: 'FETCH_DISKON_INVENTARIS_NOTEXIST'
            })
        }
    }
}

export const fetchDiskonByInventarisBatch = (id_barang_inventaris) => async dispatch => {
    const response = await mapis.get('/mart/diskon/inventaris', {
        params: {
            id: id_barang_inventaris
        }
    });
    
    if (response.data.status === "success") {
        var idx = -1;
        for (var x=0;x<response.data.data.length;x++) {
            if (response.data.data[x].jenis_pelanggan_return === JENIS_REGULER) {
                idx = x;
                break;
            }
        }
        if (idx >= 0) {
            dispatch({
                type: 'FETCH_DISKON_INVENTARIS_BATCH_EXIST',
                payload: {
                    id_barang_inventaris: id_barang_inventaris,
                    is_diskon_exist: true,
                    diskon: response.data.data[idx]
                }
            })
        }
        else {
            dispatch({
                type: 'FETCH_DISKON_INVENTARIS_BATCH_NOTEXIST',
                payload: {
                    id_barang_inventaris: id_barang_inventaris,
                    is_diskon_exist: false
                }
            })
        }
    }
}