const INITIAL_STATE_LINIMASA = {
    isLoaded: false,
    linimasa: []
}
const INITIAL_STATE_PRODUCT_BY_LINIMASA = {
    isLoaded: false,
    linimasa: null,
    products: []
}
const INITIAL_STATE_LINIMASA_INFO = {
    isLoaded: false,
    isFound: false,
    data: null
}

export const listLinimasa = (state = INITIAL_STATE_LINIMASA, action) => {
    switch (action.type) {
        case 'FETCH_RESET_LINIMASA':
            return INITIAL_STATE_LINIMASA;
        case 'FETCH_LINIMASA':
            return {...state, isLoaded: true, linimasa: action.payload};
        default:
            return state;
    }
}
export const linimasaInfo = (state = INITIAL_STATE_LINIMASA_INFO, action) => {
    switch(action.type) {
        case 'FETCH_LINIMASA_INFO_RESET':
            return INITIAL_STATE_LINIMASA_INFO;
        case 'FETCH_LINIMASA_INFO':
            return {...state, isLoaded: true, isFound: true, data: action.payload};
        case 'FETCH_LINIMASA_INFO_NOTFOUND':
            return {...state, isLoaded: true, isFound: false}
        default:
            return state;
    }
}
export const listProductByLinimasaId = (state = INITIAL_STATE_PRODUCT_BY_LINIMASA, action) => {
    switch(action.type) {
        case 'FETCH_PRODUCT_BY_LINIMASA_RESET':
            return INITIAL_STATE_PRODUCT_BY_LINIMASA;
        case 'FETCH_PRODUCT_BY_LINIMASA':
            return {...state, isLoaded: true, linimasa: action.id_linimasa, products: action.payload};
        default:
            return state;
    }
}