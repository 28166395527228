import React from 'react';
import {Redirect} from 'react-router-dom'
import {Helmet} from 'react-helmet';
import {connect} from 'react-redux';
import { fetchUserLogin } from '../actions/account';

import RegularBar from '../universal/RegularBar';
import Cookies from 'universal-cookie';

class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = { userEmail: "", userPassword: "", tryToLogin: false, isSuccessfullyLoggedIn: false }
        this.cookies = new Cookies();
    }
    doRenderHeader() {
        return (
            <Helmet>
                <title>Login - Henata</title>
            </Helmet>
        );
    }
    onFormSubmit = async event => {
        event.preventDefault();
        if (this.state.userEmail !== "" && this.state.userPassword !== "") {
            await this.props.fetchUserLogin(this.state.userEmail, this.state.userPassword);

            //console.log("Account:", this.props.accountLogin);
            if (this.props.accountLogin.isLoginValid === true) {
                setTimeout(() => { window.location.replace('/') }, 10)
            }
            else {
                this.setState({
                    tryToLogin: true
                })
            }
        }
    }
    onUserEmailInputChange = (event) => {
        this.setState({
            userEmail: event.target.value
        })
    }
    onUserPasswordInputChange = (event) => {
        this.setState({
            userPassword: event.target.value
        })
    }
    componentDidMount() {
        if (this.cookies.get("user")) {
            //kalau udah login, maka redirect ke home
            window.location.replace("/");
        }
    }
    doRenderWarning() {
        if (this.props.accountLogin.isLoginValid === false && this.state.tryToLogin === true) {
            return (
                <div>
                    Email/password yang anda masukkan salah.
                </div>
            );
        }
        else {
            return <div></div>
        }
    }
    doRenderPage() {
        if (!this.cookies.get("user")) {
            return (
                <div className="ui main container">
                    <div className="ui grid">
                        <div className="eight wide column">

                        </div>
                        <div className="eight wide column">
                        
                        {this.doRenderWarning()}
                        {this.doRenderLoginForm()}
                        </div>
                    </div>
                </div>
            );
        }
    }
    doRenderLoginForm() {
        return (
            <div>
                <form className="ui form" onSubmit={this.onFormSubmit}>
                    <div className="field">
                        <label>Email</label>
                        <input type="text" aria-label="userEmail" value={this.state.userEmail} onChange={this.onUserEmailInputChange} />
                    </div>
                    <div className="field">
                        <label>Password</label>
                        <input type="password" value={this.state.userPassword} onChange={this.onUserPasswordInputChange} />
                    </div>
                    <button className="ui green button" type="submit">Login</button>
                </form>
            </div>
        );
    }
    render() {
        return (
            <div>
                {this.doRenderHeader()}
                <RegularBar />
                {this.doRenderPage()}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return { accountLogin: state.accountLogin }
}
const connectLogin = connect(mapStateToProps, { fetchUserLogin })(Login);
export { connectLogin };