import mapis from '../../api/mapis';
import { JENIS_REGULER } from './types';

export const fetchProductInfo = (slug) => async dispatch => {
    const jenis_pelanggan = JENIS_REGULER; //set default = 1 dulu.

    dispatch({
        type: 'FETCH_PRODUCT_INFO_RESET'
    })
    var url = `/mart/barang/dijual?url=${slug}&jenis=${jenis_pelanggan}`;
    const response = await mapis.get(url);

    if (response.data.status ==="success") {
        dispatch({
            type: 'FETCH_PRODUCT_INFO',
            payload: response.data.data[0]
        })
    }
}

export const fetchBarangDijualDariDraft = (bodyObject) => async dispatch => {
    dispatch({
        type: 'FETCH_BARANG_DRAFT_RESET'
    })
    var url = `/mart/barang/draft/batch`;
    const response = await mapis.post(url, bodyObject);
    if (response.data.status === "success") {
        dispatch({
            type: 'FETCH_BARANG_DRAFT_SUCCESS',
            barang_dijual_payload: response.data.data.barang_dijual,
            diskon_payload: response.data.data.diskon
        })
    }
    else {
        dispatch({
            type: 'FETCH_BARANG_DRAFT_FAILED'
        })
    }
}