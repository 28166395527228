import React from 'react';
import "../css/product-detail.css"

class MetodePemesanan extends React.Component {
    
    render() {
        return (
            <div className="metode-pembayaran">
                {/* <div className="ui segment">
                    
                </div> */}
                {/* <div className="ui warning message">Mohon maaf, transaksi melalui website saat ini tidak tersedia. Transaksi dapat dilakukan di aplikasi henata</div> */}
                <p>Pesan lewat henata app! <div className="ui red mini label recommended">Recommended</div></p>
                    <a className="ui green basic button" href="https://play.google.com/store/apps/details?id=com.henata.app" target="_blank"
                    rel="noopener noreferrer"><i className="google play icon"></i>Download di Google Play</a>
                    
                    <div className="benefit">
                        <div className="ui two columns grid">
                            <div className="column">
                            <div><i className="check teal icon"></i> Harga termasuk diskon</div>
                            <div><i className="check teal icon"></i> Dapat cashback langsung</div>
                            <div><i className="check teal icon"></i> Opsi pengiriman instan</div>
                            </div>
                            <div className="column">
                            <div><i className="check teal icon"></i> Lacak pengiriman</div>
                        <div><i className="check teal icon"></i> Promo gratis ongkir dengan pembelian minim</div>
                            </div>
                        </div>
                        
                        
                    </div>
            </div>
            
        );
    }
}

export default MetodePemesanan;