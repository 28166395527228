import React from 'react';
import history from '../history';
import { Router, Route } from 'react-router-dom';

import HomePage from './pages/Home';
import Footer from './universal/Footer';
import { connectSearch as SearchPage } from './pages/Search';
import ProductDetailPage from './pages/ProductDetail';
import { connectTroli as TroliPage } from './pages/Troli';
import { connectKonfirmasi as KonfirmasiPage } from './pages/Konfirmasi';
import { connectPengumuman as PengumumanDetailPage } from './pages/Pengumuman';
import { 
    KategoriAll as KategoriAllPage, 
    connectKategoriDetail as KategoriDetailPage } from './pages/Kategori';
import { connectLinimasa as LinimasaPage } from './pages/Linimasa';
import { About as AboutPage } from './pages/About';
import { connectPrivacyPolicy as PrivacyPolicyPage, connectTermsCondition as TermsConditionPage } from './pages/Statics';
//account
import { connectLogin as LoginPage } from './pages/Login';
import { connectRegister as RegisterPage } from './pages/Register';
import './css/app.css';

class App extends React.Component {
    render() {
        return (
            <div style={{display: "flex", flexDirection: "column", minHeight: "100vh"}}>
                <Router history={history}>
                    <div style={{flex: "1"}}>
                        <Route path={'/'} exact component={HomePage} />
                        <Route path={'/search'} exact component={SearchPage} />
                        <Route path={'/product/:slug'} exact component={ProductDetailPage} />
                        <Route path={'/kategori'} exact component={KategoriAllPage} />
                        <Route path={'/kategori/:kat_url_slug/:subkat_url_slug'} exact component={KategoriDetailPage} />
                        <Route path={'/kategori/:kat_url_slug'} exact component={KategoriDetailPage} />
                        <Route path={'/pengumuman/:id'} exact component={PengumumanDetailPage} />
                        <Route path={'/promo/:id'} exact component={LinimasaPage} />
                        <Route path={'/troli'} exact component={TroliPage} />
                        <Route path={'/troli/checkout'} exact component={KonfirmasiPage} />
                        {/* <Route path={'/kategori/:id'} exact component={KategoriDetailPage} /> */}
                        {/* <Route path={'/featured'} exact component={} /> */}
                        {/* <Route path={"/featured/:id"} exact component={} /> */}

                        <Route path={'/terms'} exact component={TermsConditionPage} />
                        <Route path={'/privacy'} exact component={PrivacyPolicyPage} />
                        <Route path={'/about'} exact component={AboutPage} />

                        <Route path={'/login'} exact component={LoginPage} />
                        <Route path={'/register'} exact component={RegisterPage} />
                    </div>
                    <Footer />
                </Router>
            </div>
        );
    }
}

export default App;