import React from 'react';
import { Helmet } from 'react-helmet';
import {MOBILE_SITE} from '../actions/types'
import RegularBar from '../universal/RegularBar';
import '../css/about.css'

class About extends React.Component {
    doRenderFeaturedImage() {
        return(
            <div>

            </div>
        );
    }
    doRenderHeader() {
        var judulStr = "Tentang henata";
        return (
            <Helmet>
                <title>{judulStr}</title>
                <link rel="alternate" media="only screen and (max-width: 640px)" href={MOBILE_SITE + this.props.location.pathname} />
            </Helmet>
        );
    }
    doRenderStory() {
        var storyTitle = "Baru adalah kami."
        var storyText = "Kami menyediakan tempat baru bagi setiap orang untuk berbelanja barang kebutuhannya dengan mudah dan harga yang terjangkau, hemat biaya. Bagi yang punya mobilitas tinggi, yang tidak sempat untuk belanja barang kebutuhan di toko langganan atau di minimarket, bagi setiap orang yang menginginkan kemudahan, kami mencoba hadir untuk mereka. Kami percaya, teknologi dapat membantu kami mewujudkan itu. #BiarKamiYangUrus"
        return (
            <div className="ui main container">
                <h3 className="heading">{storyTitle}</h3>
                <p className="story">{storyText}</p>
            </div>
        );
    }
    doRenderProductFeature() {
        var firstHeadText = "Belanja lewat aplikasi"
        var firstMessageText = "Download aplikasi henata, belanja lewat aplikasi dan nikmati berbagai fasilitasnya. Mulai dari promo biaya kirim, diskon per item, kirim prioritas. Banyak hal yang sedang kami siapkan, tentunya untuk menambah pengalaman anda dan menguntungkan anda."

        var secondHeadText = "100+ produk, dan terus bertambah"
        var secondMessageText = "Kami berusaha memahami kebutuhan anda dengan terus memperbaharui dan menambah produk baru setiap saat, dari setiap lini."+ 
        " Kami adalah pemain baru yang memiliki misi untuk menghadirkan etalase produk yang lengkap, dan kami terus mengupayakan itu."

        var thirdHeadText = "Bayar di tempat"
        var thirdMessageText = `Pembayaran dapat dilakukan di alamat tujuan saat barang datang dengan uang tunai. Anda juga bisa menggunakan zenny untuk membayar.`
        
        return (
            <div className="ui grid">
                <div className="row">
                    <div className="three wide column">
                        <img src="/assets/img/about-image-app.png" className="featured-asset"/>
                    </div>
                    <div className="twelve wide column">
                        <h4>{firstHeadText}</h4>
                        <p>{firstMessageText}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="three wide column">
                        <img src="/assets/img/about-image-products.png" className="featured-asset"/>
                    </div>
                    <div className="twelve wide column">
                        <h4>{secondHeadText}</h4>
                        <p>{secondMessageText}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="three wide column">
                        <img src="/assets/img/about-image-delivery.png" className="featured-asset"/>
                    </div>
                    <div className="twelve wide column">
                        <h4>{thirdHeadText}</h4>
                        <p>{thirdMessageText}</p>
                    </div>
                </div>
            </div>
        )
    }
    doRenderDownload() {
        return (
            <div>
                <h4>Download aplikasi henata</h4>
            </div>
        );
    }
    componentDidMount() {
        window.scrollTo(0,0)
    }
    render() {
        return (
            <div>
                {this.doRenderHeader()}
                <RegularBar />
                <div>
                    {this.doRenderStory()}
                </div>
                <div className="ui main container">
                    {/* <div className="ui grid">
                        <div className="ten wide column">
                        {this.doRenderProductFeature()}
                        </div>
                        <div className="six wide column">
                        {this.doRenderDownload()}
                        </div>
                    </div> */}
                    {this.doRenderProductFeature()}
                </div>
            </div>
        );
    }
}

export {About};