import React from 'react';
import Pesanan from './DetailPesanan/Pesanan';

class DetailPesanan extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            barang_pesanan: null,
            total_harga_barang_sebelum_diskon: 0,
            total_diskon: 0,
            total_harga_barang_setelah_diskon: 0
        }
    }
    componentDidMount() {
        if (this.props.pesanan) {
            var rincian_total_barang = this.calculateTotalHargaBarang();
            
            //update state di parent
            this.props.handleDetailPesanan(
                rincian_total_barang.total_harga_barang_sebelum_diskon,
                rincian_total_barang.total_diskon,
                rincian_total_barang.total_harga_barang_setelah_diskon
            )

            //update state
            this.setState({
                barang_pesanan: this.props.pesanan,
                total_harga_barang_sebelum_diskon: rincian_total_barang.total_harga_barang_sebelum_diskon,
                total_diskon: rincian_total_barang.total_diskon,
                total_harga_barang_setelah_diskon: rincian_total_barang.total_harga_barang_setelah_diskon
            })
        }
    }
    doRenderSubtotal() {
        var {barang_pesanan} = this.state;
        if (barang_pesanan) {
            return (
                <div>
                    
                </div>
            )
        }
        else return <div></div>
    }
    calculateTotalHargaBarang() {
        var total_harga_barang_sebelum_diskon=0, total_diskon=0, total_harga_barang_setelah_diskon=0;
        var barang_pesanan = this.props.pesanan;
        if (barang_pesanan) {
            for (var x=0;x<barang_pesanan.length;x++) {
                var total_harga_per_barang = 0;
                var diskon_value_per_barang =0, diskon_persen_per_barang=0;
                if (barang_pesanan[x].troli) {
                    var quantityInt = parseInt(barang_pesanan[x].troli.jumlah_order);
                    total_harga_per_barang += (parseInt(barang_pesanan[x].harga_barang_return) * quantityInt);
    
                    if (barang_pesanan[x].diskon) {
                        var diskonObject = barang_pesanan[x].diskon;
                        if (diskonObject.min_pembelian_return === -1 && diskonObject.max_pembelian_return === -1) {
                            diskon_value_per_barang = parseInt(diskonObject.diskon_value_return) * quantityInt;
                            diskon_persen_per_barang = (total_harga_per_barang * parseInt(diskonObject.diskon_persen_return)) / 100;
                        }
                        else if (diskonObject.min_pembelian_return === -1 && diskonObject.max_pembelian_return > 0) {
                            if (quantityInt <= diskonObject.max_pembelian_return && quantityInt > 0) {
                                diskon_value_per_barang = parseInt(diskonObject.diskon_value_return) * quantityInt;
                                diskon_persen_per_barang = (total_harga_per_barang * parseInt(diskonObject.diskon_persen_return)) / 100;
                            }
                        }
                        else if (diskonObject.min_pembelian_return > 0 && diskonObject.max_pembelian_return === -1) {
                            if (diskonObject.min_pembelian_return > 1) {
                                if ( quantityInt >= diskonObject.min_pembelian_return) {
                                    diskon_value_per_barang = parseInt(diskonObject.diskon_value_return) * quantityInt;
                                    diskon_persen_per_barang = (total_harga_per_barang * parseInt(diskonObject.diskon_persen_return)) / 100;
                                }
                            }
                            else {
                                diskon_value_per_barang = parseInt(diskonObject.diskon_value_return) * quantityInt;
                                diskon_persen_per_barang = (total_harga_per_barang * parseInt(diskonObject.diskon_persen_return)) / 100;
                            }
                        }
                        else if (diskonObject.min_pembelian_return > 0 && diskonObject.max_pembelian_return > 0) {
                            if (quantityInt >= diskonObject.min_pembelian_return && quantityInt <= diskonObject.max_pembelian_return) {
                                diskon_value_per_barang = parseInt(diskonObject.diskon_value_return) * quantityInt;
                                diskon_persen_per_barang = (total_harga_per_barang * parseInt(diskonObject.diskon_persen_return)) / 100;
                            }
                        }
                    }
    
                    total_harga_barang_sebelum_diskon += total_harga_per_barang;
                    total_diskon += (diskon_value_per_barang + diskon_persen_per_barang);
                }
            }
        }

        total_harga_barang_setelah_diskon = total_harga_barang_sebelum_diskon - total_diskon;

        return {
            total_harga_barang_sebelum_diskon,
            total_harga_barang_setelah_diskon,
            total_diskon
        }
    }
    doRenderDetailPesanan() {
        var {barang_pesanan} = this.state;
        if (barang_pesanan) {
            var renderedPesanan = barang_pesanan.map(function(element) {
                return (
                    <Pesanan barang={element} />
                )
            });
    
            return (
                <div>
                    <h4 className="heading">Detail Pesanan</h4>
                    <div className="ui items">
                        {renderedPesanan}
                    </div>
                </div>
            )
        }
        else {
            return <div></div>
        }
    }
    render() {
        return (
            <div>
                {this.doRenderDetailPesanan()}
            </div>
        );
    }
}

export default DetailPesanan;